export function addPedido(
  id,
  Numero,
  Sociedad,
  NomProveedor,
  Pedido,
  Estatus,
  Fecha,
  FechaVencimiento,
  DiasCredito,
  UsoPrincipal,
  MetodoPago,
  FormaPago,
  Total,
  Referencia,
  Comentarios,
  Descuento,
  TotalDescuento,
  TotalImpuestos,
  PDF,
  DB,
  Moneda,
  DetallePedido
) {
  return {
    id,
    Numero,
    Sociedad,
    NomProveedor,
    Pedido,
    Estatus,
    Fecha,
    FechaVencimiento,
    DiasCredito,
    UsoPrincipal,
    MetodoPago,
    FormaPago,
    Total,
    Referencia,
    Comentarios,
    Descuento,
    TotalDescuento,
    TotalImpuestos,
    PDF,
    DB,
    Moneda,
    DetallePedido,
  };
}
export function DetallePedido(
  id,
  Numero,
  Codigo,
  Descripcion,
  Estatus,
  Unidad,
  Cantidad,
  CantidadAbierta,
  CantidadEntregada,
  DiasCredito,
  UsoPrincipal,
  MetodoPago,
  PrecioUnidad,
  Descuento,
  Total
) {
  return {
    id,
    Numero,
    Codigo,
    Descripcion,
    Estatus,
    Unidad,
    Cantidad,
    CantidadAbierta,
    CantidadEntregada,
    DiasCredito,
    UsoPrincipal,
    MetodoPago,
    PrecioUnidad,
    Descuento,
    Total,
  };
}

export const CeldasPedidos = [
  {
    id: "#",
    numeric: true,
    disablePadding: true,
    label: "#",
    minWidth: "2em",
  },
  {
    id: "Sociedad",
    numeric: false,
    disablePadding: true,
    label: "Sociedad",
    minWidth: "15em",
  },
  {
    id: "NomProveedor",
    numeric: false,
    disablePadding: false,
    label: "Nombre del proveedor",
    minWidth: "15em",
  },
  {
    id: "Pedido",
    numeric: true,
    disablePadding: false,
    label: "Pedido",
    minWidth: "5em",
  },
  {
    id: "Estatus",
    numeric: false,
    disablePadding: false,
    label: "Estatus",
    minWidth: "10em",
  },
  {
    id: "Fecha",
    numeric: false,
    disablePadding: false,
    label: "Fecha del pedido",
    minWidth: "12em",
  },
  {
    id: "FechaVencimiento",
    numeric: false,
    disablePadding: false,
    label: "Fecha acordada",
    minWidth: "12em",
  },
  {
    id: "DiasCredito",
    numeric: false,
    disablePadding: false,
    label: "Días de crédito",
    minWidth: "12em",
  },
  {
    id: "UsoPrincipal",
    numeric: false,
    disablePadding: false,
    label: "Uso principal",
    minWidth: "12em",
  },
  {
    id: "MetodoPago",
    numeric: false,
    disablePadding: false,
    label: "Método de pago",
    minWidth: "12em",
  },
  {
    id: "FormaPago",
    numeric: false,
    disablePadding: false,
    label: "Forma de pago",
    minWidth: "12em",
  },
  {
    id: "Total",
    numeric: true,
    disablePadding: false,
    label: "Total",
    minWidth: "10em",
  },
];

export const CeldasPedidoDetalle = [
  {
    id: "#",
    numeric: true,
    disablePadding: false,
    label: "#",
    minWidth: "3em",
  },
  {
    id: "Descripcion",
    numeric: false,
    disablePadding: false,
    label: "Descripción",
    minWidth: "10em",
  },
  {
    id: "NombreExtranjero",
    numeric: false,
    disablePadding: false,
    label: "Nombre extranjero",
    minWidth: "10em",
  },
  {
    id: "Estatus",
    numeric: false,
    disablePadding: false,
    label: "Estatus",
  },

  {
    id: "Unidad",
    numeric: false,
    disablePadding: false,
    label: "Unidad de medida",
  },
  {
    id: "CantidadPedido",
    numeric: true,
    disablePadding: false,
    label: "Cantidad pedido",
  },
  {
    id: "CantidadAbierta",
    numeric: true,
    disablePadding: false,
    label: "Cantidad abierta",
  },
  {
    id: "CantidadEntreagda",
    numeric: true,
    disablePadding: false,
    label: "Cantidad entregada",
  },
  // {
  //   id: "DiasCredito",
  //   numeric: true,
  //   disablePadding: false,
  //   label: "Dias de credito",
  // },
  // {
  //   id: "UsoPrincipal",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "Uso principal",
  // },
  // {
  //   id: "MetodoPago",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "Metodo de pago",
  // },
  {
    id: "PrecioUnidad",
    numeric: true,
    disablePadding: false,
    label: "Precio por unidad",
    minWidth: "10em",
  },

  {
    id: "Descuento",
    numeric: true,
    disablePadding: false,
    label: "Descuento",
  },
  {
    id: "Total",
    numeric: true,
    disablePadding: false,
    label: "Total",
    minWidth: "10em",
  },
];
