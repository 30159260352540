export function addProveedor(
  id,
  Numero,
  CodigoSAP,
  TipoPersona,
  RazonSocial,
  RFC,
  Telefono,
  Correo,
  PersonaContacto,
  Contraseña,
  Estatus,
  EstatusRegistro,
  Motivo,
  Sociedades,

  ValActaConstitutiva,
  DiasActaConstitutiva,
  ActaConstitutiva,
  FechaActaConstitutiva,

  ValIdentificacion,
  DiasIdentificacion,
  Identificacion,
  FechaIdentificacion,

  ValComprobante,
  DiasComprobante,
  Comprobante,
  FechaComprobante,

  ValCedula,
  DiasCedula,
  Cedula,
  FechaCedula,

  ValEdoCuenta,
  DiasEdoCuenta,
  EdoCuenta,
  FechaEdoCuenta,

  ValObligacionFiscal,
  DiasObligacionFiscal,
  ObligacionFiscal,
  FechaObligacionFiscal,

  DocAdicional,
  FechaDocAdicional
) {
  return {
    id,
    Numero,
    CodigoSAP,

    TipoPersona,
    RazonSocial,
    RFC,
    Telefono,
    Correo,
    PersonaContacto,
    Contraseña,
    Estatus,
    EstatusRegistro,
    Motivo,
    Sociedades,

    ValActaConstitutiva,
    DiasActaConstitutiva,
    ActaConstitutiva,
    FechaActaConstitutiva,

    ValIdentificacion,
    DiasIdentificacion,
    Identificacion,
    FechaIdentificacion,

    ValComprobante,
    DiasComprobante,
    Comprobante,
    FechaComprobante,

    ValCedula,
    DiasCedula,
    Cedula,
    FechaCedula,

    ValEdoCuenta,
    DiasEdoCuenta,
    EdoCuenta,
    FechaEdoCuenta,

    ValObligacionFiscal,
    DiasObligacionFiscal,
    ObligacionFiscal,
    FechaObligacionFiscal,

    DocAdicional,
    FechaDocAdicional,
  };
}
export const CeldasProveedores = [
  {
    id: "#",
    align: "Left",
    disablePadding: true,
    label: "#",
    minWidth: "5em",
  },
  {
    id: "CodigoSAP",
    align: "Left",
    disablePadding: true,
    label: "Código SAP",
    minWidth: "10em",
  },
  {
    id: "TipoPersona",
    align: "Left",
    disablePadding: true,
    label: "Tipo de persona",
    minWidth: "12em",
  },
  {
    id: "RazonSocial",
    align: "Left",
    disablePadding: true,
    label: "Razón Social",
    minWidth: "12em",
  },
  {
    id: "RFC",
    align: "Left",
    disablePadding: true,
    label: "RFC",
    minWidth: "12em",
  },
  {
    id: "Telefono",
    align: "Left",
    disablePadding: true,
    label: "Teléfono",
    minWidth: "12em",
  },
  {
    id: "Correo",
    align: "Left",
    disablePadding: true,
    label: "Correo",
    minWidth: "12em",
  },
  {
    id: "PersonaContacto",
    align: "Left",
    disablePadding: true,
    label: "Persona de Contacto",
    minWidth: "15em",
  },
  {
    id: "Estatus",
    align: "Left",
    disablePadding: true,
    label: "Estatus",
    minWidth: "8em",
  },
];
