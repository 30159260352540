import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import {
  TableContainer,
  Table,
  Paper,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
} from "@mui/material";
import { CeldasPedidoDetalle } from "../../Data/Pedidos-Data";
import { FormatoMoneda } from "../../Data/DatosSAT";

function EncabezadoDatos() {
  return (
    <TableHead>
      <TableRow>
        {CeldasPedidoDetalle.map((headCell) => (
          <TableCell
            key={headCell.id}
            size="small"
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "checkbox" : "normal"}
            sx={{ minWidth: headCell.minWidth }}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function PedidoTablaDetalle(props) {
  const pedidoSubtotal = subtotal(props.rows);

  function subtotal(items) {
    return items.map(({ Total }) => Total).reduce((Total, i) => Total + i, 0);
  }

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%" }}>
        <TableContainer>
          <Table>
            <EncabezadoDatos />
            <TableBody>
              {props.rows.map((row, index) => {
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.id}
                    sx={{ cursor: "pointer" }}
                  >
                    <TableCell
                      component="th"
                      id={labelId}
                      key={labelId}
                      scope="row"
                      padding="checkbox"
                      align="center"
                    >
                      {row.Numero}
                    </TableCell>
                    <TableCell>{row.Codigo}</TableCell>
                    <TableCell>{row.Descripcion}</TableCell>
                    <TableCell>{row.Estatus}</TableCell>
                    <TableCell>{row.Unidad}</TableCell>
                    <TableCell align="right">
                      {" "}
                      {FormatoMoneda(row.Cantidad)}
                    </TableCell>
                    <TableCell align="right">
                      {FormatoMoneda(row.CantidadAbierta)}
                    </TableCell>
                    <TableCell align="right">
                      {FormatoMoneda(row.CantidadEntregada)}
                    </TableCell>
                    <TableCell align="right">
                      {FormatoMoneda(parseFloat(row.PrecioUnidad)) +
                        " " +
                        props.EncabezadoPedido.Moneda}
                    </TableCell>
                    <TableCell align="right">
                      {FormatoMoneda(row.Descuento) + " %"}
                    </TableCell>
                    <TableCell align="right">
                      {FormatoMoneda(row.Total) +
                        " " +
                        props.EncabezadoPedido.Moneda}
                    </TableCell>
                  </TableRow>
                );
              })}

              <TableRow>
                <TableCell rowSpan={5} colSpan={8} />

                <TableCell colspan={1} align="right">
                  Subtotal
                </TableCell>
                <TableCell colspan={2} align="right">
                  <strong>
                    {FormatoMoneda(pedidoSubtotal) +
                      " " +
                      props.EncabezadoPedido.Moneda}{" "}
                  </strong>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell align="right" colspan={1}>
                  Descuento %
                </TableCell>
                <TableCell align="right">
                  <strong>
                    {FormatoMoneda(props.EncabezadoPedido.Descuento)}
                  </strong>
                </TableCell>
                <TableCell align="right">
                  <strong>
                    {FormatoMoneda(props.EncabezadoPedido.TotalDescuento) +
                      " " +
                      props.EncabezadoPedido.Moneda}
                  </strong>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colspan={1} align="right">
                  Impuestos
                </TableCell>
                <TableCell colspan={2} align="right">
                  <strong>
                    {FormatoMoneda(props.EncabezadoPedido.TotalImpuestos) +
                      " " +
                      props.EncabezadoPedido.Moneda}
                  </strong>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colspan={1} align="right">
                  Total
                </TableCell>
                <TableCell colspan={2} align="right">
                  <strong>
                    {" "}
                    {props.EncabezadoPedido.Total +
                      " " +
                      props.EncabezadoPedido.Moneda}
                  </strong>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
}

PedidoTablaDetalle.propTypes = {
  row: PropTypes.object,
};

export default PedidoTablaDetalle;
