import React from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import { useNavigate, Navigate } from "react-router-dom";
import { Header, MenuLateral } from "../Header/Header";
import {
  Box,
  Toolbar,
  Typography,
  Select,
  Grid,
  Snackbar,
  FormControl,
  MenuItem,
  TextField,
  OutlinedInput,
  InputAdornment,
  Alert,
  Checkbox,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { isAuthenticatedvalid } from "../Router/Cookies";
import { ThemeProvider } from "@mui/material/styles";
import {
  theme,
  StyledButton,
  StylePaperPrincipal,
  StyledDiv,
  StyledTheme,
  StylePaperButton,
  StyledInputFile,
  StyledButtonEditar,
} from "./CtaSociedades-css";
import Cookies from "js-cookie";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { convertToBase64 } from "../Data/ArchivoBase64";

function Sociedad(props) {
  const navigate = useNavigate();
  let params = useParams();

  const [values, setValues] = React.useState({
    id: "0",
    RazonSocial: "",
    RFC: "",
    RegimenFiscal: "",
    CodigoPostal: "",
    Estatus: "I",
    UsuarioSAP: "",
    ContraseñaSAP: "",
    BDSAP: "",
    CartaSituacionFiscalNombre: "",
    CartaSituacionFiscal: "",
  });
  const [habilitar, setHabilitar] = React.useState(false);
  const [habilitarBD, setHabilitarBD] = React.useState(true);
  const [Generacion, setGeneracion] = React.useState(false);
  const [openMessage, setOpenMessage] = React.useState(false);
  const [habilarBoton, setHabilarBoton] = React.useState(false);
  const [crear, setCrear] = React.useState("Crear");
  const [showPassword, setShowPassword] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [errorMensaje, setErrorMensaje] = React.useState("");

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleChangeCheck = () => setHabilitarBD((habilitarBD) => !habilitarBD);

  const operador = Cookies.get("operador");
  const tipoEmpleado = Cookies.get("tipoEmpleado");
  const access = Cookies.get("access");
  let IsSession = isAuthenticatedvalid("access");

  React.useEffect(() => {
    if (params.id !== "Nuevo") {
      if (props.sociedades.length > 0) {
        setValues({
          id: props.sociedades[params.id - 1].Numero,
          RazonSocial: props.sociedades[params.id - 1].RazonSocial,
          RFC: props.sociedades[params.id - 1].RFC,
          RegimenFiscal: props.sociedades[params.id - 1].RegimenFiscal,
          CodigoPostal: props.sociedades[params.id - 1].CodigoPostal,
          UsuarioSAP: props.sociedades[params.id - 1].UsuarioSAP,
          ContraseñaSAP: props.sociedades[params.id - 1].ContraseñaSAP,
          BDSAP: props.sociedades[params.id - 1].BDSAP,
          Estatus:
            props.sociedades[params.id - 1].Estatus === "Activo" ? "A" : "I",
          CartaSituacionFiscal:
            props.sociedades[params.id - 1].CartaSituacionFiscal,
          CartaSituacionFiscalNombre: props.sociedades[params.id - 1]
            .CartaSituacionFiscal
            ? "Cargado"
            : "No cargado",
        });

        if (operador === "Empleado" && tipoEmpleado === "Administrador") {
          setHabilitar(false);
        } else {
          setHabilitar(true);
          setHabilarBoton(true);
        }

        setCrear("Actualizar");
      } else {
        navigate("/Catalogo-Sociedades");
      }
    }
  }, [navigate, operador, params.id, props.sociedades, tipoEmpleado]);

  async function handleSubmit(e) {
    e.preventDefault();
    console.log(values.CartaSituacionFiscal);
    if (values.CartaSituacionFiscal === "") {
      setErrorMensaje("Debe cargar la carta de situación fiscal");
      setError(true);
      setOpenMessage(true);
      return;
    }
    let url;
    if (crear === "Crear") {
      url = "/api/sociedades/createSociedad";
    } else {
      url = "/api/sociedades/updateSociedad";
    }

    const Peticion = new Request(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-access-token": access,
      },
      method: "POST",
      body: JSON.stringify(values),
    });

    try {
      const response = await fetch(Peticion);
      const data = await response.json();
      if (data.status === "success") {
        setHabilarBoton(true);
        setGeneracion(true);
        setOpenMessage(true);
        setError(false);
      } else {
        setError(true);
        setOpenMessage(true);
        setErrorMensaje(data.message);
      }
    } catch (error) {
      console.error("Failed to fetch from updateSociedad", error);
    }
  }

  function handleChange(evt) {
    const { target } = evt;
    const { name, value } = target;
    const newValues = {
      ...values,
      [name]: value,
    };
    setValues(newValues);
  }

  const handleChangePDF = async (evt) => {
    const { target } = evt;
    if (target.value !== "") {
      const { name, files, id } = target;
      var base64;
      try {
        const base64pdf = await convertToBase64(files[0]);

        var ArrayAuxiliar = [];
        ArrayAuxiliar = base64pdf.split(",");
        base64 = ArrayAuxiliar[1];
      } catch (error) {
        console.error("Error while converting to Base64:", error);
      }

      const newValues = {
        ...values,
        [name]: files[0].name,
        [id]: base64,
      };

      setValues(newValues);
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenMessage(false);

    if (Generacion === true) {
      navigate("/Catalogo-Sociedades");
    }
  };

  if (IsSession) {
    return (
      <ThemeProvider theme={theme}>
        <Box sx={{ display: "flex" }}>
          <Header
            IsSession={IsSession}
            open={props.open}
            setOpen={props.setOpen}
            operador={Cookies.get("operador")}
            usuario={Cookies.get("usuario")}
          />
          <MenuLateral
            IsSession={IsSession}
            open={props.open}
            setOpen={props.setOpen}
          />
          <Box
            component="main"
            sx={{
              flexGrow: 10,
              height: "100vh",
              overflow: "auto",
            }}
          >
            <Toolbar />
            <Typography
              sx={{ my: 3, mx: 2 }}
              align="center"
              variant="h5"
              component="h5"
            >
              Datos generales
            </Typography>

            <form onSubmit={handleSubmit}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <StylePaperPrincipal variant="outlined">
                  <StyledDiv>
                    <Typography
                      align="left"
                      variant="subtitle2"
                      gutterBottom
                      sx={window.innerWidth > 500 && { width: 1 / 2 }}
                    >
                      *Razón social:
                    </Typography>
                    <TextField
                      fullWidth
                      align="left"
                      autoComplete="section-sociedades-RazonSocial"
                      size="small"
                      variant="outlined"
                      required
                      id="RazonSocial"
                      name="RazonSocial"
                      autoFocus
                      value={values.RazonSocial}
                      disabled={habilitar}
                      onChange={handleChange}
                    />
                  </StyledDiv>
                  <StyledDiv>
                    <Typography
                      align="left"
                      variant="subtitle2"
                      gutterBottom
                      sx={window.innerWidth > 500 && { width: 1 / 2 }}
                    >
                      *RFC:
                    </Typography>
                    <TextField
                      fullWidth
                      align="left"
                      autoComplete="section-sociedades-RFC"
                      size="small"
                      variant="outlined"
                      required
                      id="RFC"
                      name="RFC"
                      value={values.RFC}
                      disabled={habilitar}
                      onChange={handleChange}
                    />
                  </StyledDiv>
                  <StyledDiv>
                    <Typography
                      align="left"
                      variant="subtitle2"
                      gutterBottom
                      sx={window.innerWidth > 500 && { width: 1 / 2 }}
                    >
                      *Régimen fiscal:
                    </Typography>
                    <TextField
                      fullWidth
                      align="left"
                      autoComplete="section-Sociedades-RegimenFiscal"
                      size="small"
                      variant="outlined"
                      required
                      id="RegimenFiscal"
                      name="RegimenFiscal"
                      value={values.RegimenFiscal}
                      disabled={habilitar}
                      onChange={handleChange}
                    />
                  </StyledDiv>
                  <StyledDiv>
                    <Typography
                      align="left"
                      variant="subtitle2"
                      gutterBottom
                      sx={window.innerWidth > 500 && { width: 1 / 2 }}
                    >
                      *Código postal:
                    </Typography>
                    <TextField
                      fullWidth
                      align="left"
                      autoComplete="section-sociedades-CodigoPostal"
                      size="small"
                      variant="outlined"
                      required
                      id="CodigoPostal"
                      name="CodigoPostal"
                      value={values.CodigoPostal}
                      disabled={habilitar}
                      onChange={handleChange}
                    />
                  </StyledDiv>
                  <StyledDiv>
                    <Typography
                      align="left"
                      variant="subtitle2"
                      gutterBottom
                      sx={window.innerWidth > 500 && { width: 1 / 2 }}
                    >
                      *Usuario SAP B1:
                    </Typography>
                    <TextField
                      autoComplete="section-sociedades-UsuarioSAP"
                      size="small"
                      fullWidth
                      variant="outlined"
                      required
                      id="UsuarioSAP"
                      name="UsuarioSAP"
                      value={values.UsuarioSAP}
                      onChange={handleChange}
                      disabled={habilitar}
                    />
                  </StyledDiv>

                  <StyledDiv>
                    <Typography
                      align="left"
                      variant="subtitle2"
                      gutterBottom
                      sx={window.innerWidth > 500 && { width: 1 / 2 }}
                    >
                      *Contraseña SAP B1:
                    </Typography>
                    <FormControl fullWidth variant="outlined">
                      <OutlinedInput
                        autoComplete="section-sociedades-ContraseñaSAP"
                        size="small"
                        fullWidth
                        variant="outlined"
                        required
                        id="ContraseñaSAP"
                        name="ContraseñaSAP"
                        value={values.ContraseñaSAP}
                        onChange={handleChange}
                        disabled={habilitar}
                        type={showPassword ? "text" : "password"}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              onClick={handleClickShowPassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                  </StyledDiv>

                  <StyledDiv>
                    <Typography
                      align="left"
                      variant="subtitle2"
                      gutterBottom
                      sx={window.innerWidth > 500 && { width: 1 / 2 }}
                    >
                      *BD SAP B1:
                    </Typography>
                    <Checkbox onChange={handleChangeCheck}></Checkbox>
                    <TextField
                      autoComplete="section-sociedades-BDSAP"
                      size="small"
                      fullWidth
                      variant="outlined"
                      required
                      id="BDSAP"
                      name="BDSAP"
                      value={values.BDSAP}
                      onChange={handleChange}
                      disabled={habilitarBD}
                    />
                  </StyledDiv>

                  <StyledDiv>
                    <Typography
                      align="left"
                      variant="subtitle2"
                      gutterBottom
                      sx={window.innerWidth > 500 && { width: 1 / 2 }}
                    >
                      *Estatus:
                    </Typography>
                    <Select
                      autoComplete="section-sociedades-Estatus"
                      size="small"
                      fullWidth
                      align="left"
                      variant="outlined"
                      required
                      id="Estatus"
                      name="Estatus"
                      value={values.Estatus}
                      onChange={handleChange}
                      disabled={habilitar}
                    >
                      <MenuItem id="area-A" value="A">
                        Activo
                      </MenuItem>
                      <MenuItem id="area-I" value="I">
                        Inactivo
                      </MenuItem>
                    </Select>
                  </StyledDiv>
                  {/* Constancia de situación fiscal  */}
                  <StyledDiv>
                    <Typography
                      align="left"
                      variant="subtitle2"
                      gutterBottom
                      sx={window.innerWidth > 500 && { width: 1 / 2 }}
                    >
                      *Constancia de situación fiscal:
                    </Typography>
                    <TextField
                      fullWidth
                      align="left"
                      autoComplete="section-sociedades-CartaSituacionFiscalNombre"
                      size="small"
                      variant="outlined"
                      required
                      id="CartaSituacionFiscalNombre"
                      name="CartaSituacionFiscalNombre"
                      value={values.CartaSituacionFiscalNombre}
                      disabled={true}
                    />
                    <StyledButtonEditar
                      size="medium"
                      component="label"
                      role={undefined}
                      tabIndex={-1}
                      endIcon={<CloudUploadIcon />}
                      id="CartaSituacionFiscal"
                      name="CartaSituacionFiscal"
                      onChange={handleChangePDF}
                      multiple={false}
                    >
                      cargar
                      <StyledInputFile
                        id="CartaSituacionFiscal"
                        name="CartaSituacionFiscalNombre"
                        type="file"
                        accept="application/pdf"
                        disabled={habilitar}
                      />
                    </StyledButtonEditar>
                  </StyledDiv>
                </StylePaperPrincipal>
              </Grid>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <StylePaperButton variant="elevation" elevation={0}>
                  <StyledButton
                    type="submit"
                    variant="contained"
                    color="primary"
                    className={StyledTheme.submit}
                    disabled={habilarBoton}
                  >
                    {crear}
                  </StyledButton>
                </StylePaperButton>
              </Grid>
            </form>
          </Box>
        </Box>
        {Generacion === true && (
          <Snackbar
            anchorOrigin={{
              vertical: "center",
              horizontal: "center",
            }}
            open={openMessage}
            autoHideDuration={3000}
            onClose={handleClose}
          >
            <Alert
              onClose={handleClose}
              severity="success"
              variant="filled"
              sx={{ width: "100%" }}
            >
              Se {crear === "Actualizar" ? "actualizó" : "creó"} con éxito la
              sociedad!
            </Alert>
          </Snackbar>
        )}

        {error === true && (
          <Snackbar
            open={openMessage}
            autoHideDuration={3000}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "center",
              horizontal: "center",
            }}
          >
            <Alert
              onClose={handleClose}
              severity="error"
              variant="filled"
              sx={{ width: "100%" }}
            >
              {errorMensaje}
            </Alert>
          </Snackbar>
        )}
      </ThemeProvider>
    );
  } else {
    return <Navigate to="/" />;
  }
}

Sociedad.propTypes = {
  sociedades: PropTypes.array,
};

export default Sociedad;
