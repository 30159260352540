import React from "react";
import PropTypes from "prop-types";
import { Navigate } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import {
  Box,
  Grid,
  Toolbar,
  Paper,
  Typography,
  AppBar,
  Tooltip,
  Snackbar,
  Alert,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import RefreshIcon from "@mui/icons-material/Refresh";
import {
  theme,
  StyledSearch,
  StyledSearchIconWrapper,
  StyledInputBase,
  StyledButtonReload,
  StylePaperLoad,
} from "./Styled-PagosPendientes";
import Cookies from "js-cookie";
import { Header, MenuLateral } from "../Header/Header";
import { addPagoPendiente } from "../Data/PagosPendientes-Data";
import { UpdateAccessToken } from "../Router/Cookies";
import FacturasPendientesTabla from "./PagosPendientesTabla";
import PagosPendientesDialog from "./PagosPendientesDialog";
import { FormatoMoneda } from "../Data/DatosSAT";
import CircularProgress from "@mui/material/CircularProgress";

function PagosPendientes(props) {
  const [filtro, setFiltro] = React.useState("");
  const [selected, setSelected] = React.useState([]);
  const { pagosPendientes, setPagosPendientes } = props;

  const usuario = Cookies.get("usuario");
  const operador = Cookies.get("operador");
  const access = Cookies.get("access");
  const id = Cookies.get("id");
  let IsSession = !!Cookies.get("access");
  const [error, setError] = React.useState(false);
  const [errorMensaje, setErrorMensaje] = React.useState("");
  const [openMessage, setOpenMessage] = React.useState(false);
  const [cargar, setCargar] = React.useState(true);
  const [tipoMensaje, setTipoMensaje] = React.useState("");

  React.useEffect(() => {
    setPagosPendientes([]);
    fetchData(setPagosPendientes);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = async (setPagos) => {
    setSelected([]);
    setPagosPendientes([]);
    setCargar(true);
    const Values = {
      usuario: id,
      operador: operador,
    };
    const Peticion = new Request("api/pagosPendientes/getPagos", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-access-token": access,
      },
      method: "POST",
      body: JSON.stringify(Values),
    });

    let DatosArray = [];
    try {
      const response = await fetch(Peticion);
      const data = await response.json();
      const Datos = data.data;

      Datos.map((item) =>
        DatosArray.push(
          addPagoPendiente(
            item.IdPago,
            item.Fila,
            item.Fila,
            item.NomSociedad,
            item.CardName,
            item.U_SYS_PMUUID,
            item.DocNum,
            item.U_SYS_PMFECC,
            item.DocDate,
            item.PymntGroup,
            FormatoMoneda(item.DocTotal),
            item.U_SYS_PMFECA,
            item.U_SYS_PMESPA,
            item.PDF ? item.PDF : "",
            item.U_SYS_PMPDFC ? item.U_SYS_PMPDFC : "",
            item.U_SYS_PMXMLC ? item.U_SYS_PMXMLC : "",
            false,
            item.U_SYS_PMMERR ? item.U_SYS_PMMERR : "",
            item.U_SYS_PMSNBD,
            item.E_mail,
            item.DocCur ? item.DocCur : ""
          )
        )
      );

      setPagos(DatosArray);
    } catch (error) {
      console.error(error);
    }
    setCargar(false);
  };

  if (!UpdateAccessToken()) {
    return <Navigate to="/" />;
  }
  const handleForma = (event) => {
    event.preventDefault();
    fetchData(setPagosPendientes);
  };

  const handleReload = () => {
    fetchData(setPagosPendientes);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenMessage(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          display: "flex",
        }}
      >
        <Header
          IsSession={IsSession}
          open={props.open}
          setOpen={props.setOpen}
          operador={operador}
          usuario={usuario}
        />
        <MenuLateral
          IsSession={IsSession}
          open={props.open}
          setOpen={props.setOpen}
        />
        <Box
          sx={{
            flexGrow: 10,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />

          <Typography
            color="text.secondary"
            align="center"
            variant="h4"
            component="h4"
            margin={2}
          >
            Complemento de pagos pendientes
          </Typography>
          <Paper sx={{ maxWidth: "90%", margin: "auto", overflow: "hidden" }}>
            <AppBar
              position="static"
              sx={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }}
            >
              <Toolbar>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs>
                    <StyledSearch>
                      <StyledSearchIconWrapper>
                        <SearchIcon />
                      </StyledSearchIconWrapper>
                      <StyledInputBase
                        placeholder="Search…"
                        inputProps={{ "aria-label": "search" }}
                        value={filtro}
                        onChange={(e) => setFiltro(e.target.value)}
                      />
                    </StyledSearch>
                  </Grid>
                  <Grid>
                    <Tooltip title="Recargar">
                      <StyledButtonReload
                        color="inherit"
                        onClick={handleForma}
                        size="medium"
                      >
                        <RefreshIcon
                          id="btnRefreshEmpleado"
                          color="inherit"
                          sx={{ display: "block" }}
                        />
                      </StyledButtonReload>
                    </Tooltip>
                  </Grid>
                </Grid>
              </Toolbar>
              {
                <FacturasPendientesTabla
                  filtro={filtro}
                  rows={pagosPendientes}
                  selected={selected}
                  setSelected={setSelected}
                  setRows={setPagosPendientes}
                />
              }
            </AppBar>
            <PagosPendientesDialog
              selected={selected}
              rows={pagosPendientes}
              handleReload={handleReload}
              setError={setError}
              setErrorMensaje={setErrorMensaje}
              setOpenMessage={setOpenMessage}
              setTipoMensaje={setTipoMensaje}
            />
            {error === true && (
              <Snackbar
                anchorOrigin={{
                  vertical: "center",
                  horizontal: "center",
                }}
                open={openMessage}
                autoHideDuration={2000}
                onClose={handleClose}
              >
                <Alert
                  onClose={handleClose}
                  severity={tipoMensaje}
                  variant="filled"
                  sx={{ width: "100%" }}
                >
                  {errorMensaje}
                </Alert>
              </Snackbar>
            )}
            <StylePaperLoad>
              {cargar === true && <CircularProgress />}
            </StylePaperLoad>
          </Paper>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

PagosPendientes.propTypes = {
  pagosPendientes: PropTypes.array,
};

export default PagosPendientes;
