import React from "react";
import PropTypes from "prop-types";
import Cookies from "js-cookie";
import { ThemeProvider } from "@mui/material";
import { theme, StyledPaper, StyledCard } from "./RecuperarPassword-css";
import { Box, Grid, Toolbar, Paper, Typography } from "@mui/material";
import { Header } from "../Header/Header";
function RecuperarPassword(props) {
  const operador = React.useState(Cookies.get("operador"));
  const usuario = Cookies.get("usuario");

  if (operador === "Proveedor") {
    return <div>AvisoPrivacidad {operador}</div>;
  } else {
    return (
      <ThemeProvider theme={theme}>
        <Box sx={{ display: "flex" }}>
          <Header
            IsSession={false}
            open={false}
            setOpen={props.setOpen}
            operador={operador}
            usuario={usuario}
          />
          <Box
            sx={{
              flexGrow: 10,
              height: "100vh",
              overflow: "auto",
            }}
          >
            <Toolbar />

            <Typography
              color="text.secondary"
              align="center"
              variant="h4"
              component="h4"
              margin={2}
            >
              Recuperación de contraseña
            </Typography>
            <Paper sx={{ maxWidth: "90%", margin: "auto", overflow: "hidden" }}>
              <div>
                <p>&iquest;Tiene problemas para iniciar sesi&oacute;n?</p>
                <p>
                  Debe de ponerse en contacto con el usuario administrador del
                  portal.
                  <br />
                  <br />
                </p>
                <p>Persona a cargo: Agasys</p>
                <p>
                  Correo:&nbsp;
                  <a href="mailto:gvergara@agasys.com.mx">
                    gvergara@agasys.com.mx
                  </a>
                </p>
                <p>Tel: 55596523631</p>
                <p>
                  envia un correo pidiendiendo restablecer tu contrase&ntilde;a.
                </p>
                <p>
                  el correo debe de incluir el codigo de usuario o el correo con
                  el que se tiene registrado.
                </p>
                <p>
                  a la brevedad se estara contactando el usuario administrador
                  para proporcionar la nueva contrase&ntilde;a.
                </p>
              </div>
            </Paper>
          </Box>
        </Box>
      </ThemeProvider>
    );
  }
}

RecuperarPassword.propTypes = {};

export default RecuperarPassword;
