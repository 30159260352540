import ImgCatSoc from "../../Assets/CatalogoSoc.png";
import ImgCatProv from "../../Assets/CatalogoPro.png";
import ImgFacPen from "../../Assets/FacturasPen.png";
import ImgMisFac from "../../Assets/MisFacturas.png";
import ImgEntradas from "../../Assets/Entrada.png";
import ImgPedi from "../../Assets/Pedidos.png";
import ImgCatEmp from "../../Assets/catalogoEmpleados.png";
import ImgPagoPendiente from "../../Assets/Pagos.png";
import ImgPago from "../../Assets/ComplementoPago.png";

export const Menus = [
  {
    id: 0,
    idName: "btnCatEmp",
    name: "Catálogo de empleados",
    Proveedor: false,
    Empleado: true,
    Imagen: ImgCatEmp,
    Registro: 0,
  },
  {
    id: 1,
    idName: "btnCatPro",
    name: "Catálogo de proveedores",
    Proveedor: false,
    Empleado: true,
    Imagen: ImgCatProv,
    Registro: 0,
  },
  {
    id: 2,
    idName: "btnCatSoc",
    name: "Catálogo de sociedades",
    Proveedor: false,
    Empleado: true,
    Imagen: ImgCatSoc,
    Registro: 0,
  },
  {
    id: 3,
    idName: "btnPedi",
    name: "Pedidos",
    Proveedor: true,
    Empleado: true,
    Imagen: ImgPedi,
    Registro: 0,
  },
  {
    id: 4,
    idName: "btnEntMer",
    name: "Entradas de mercancía",
    Proveedor: true,
    Empleado: true,
    Imagen: ImgEntradas,
    Registro: 0,
  },
  {
    id: 5,
    idName: "btnFacPen",
    name: "Facturas pendientes",
    Proveedor: false,
    Empleado: true,
    Imagen: ImgFacPen,
    Registro: 0,
  },
  {
    id: 6,
    idName: "btnMisFac",
    name: "Mis facturas",
    Proveedor: true,
    Empleado: true,
    Imagen: ImgMisFac,
    Registro: 0,
  },
  {
    id: 7,
    idName: "btnCoPaPe",
    name: "Complemento de pago pendientes",
    Proveedor: false,
    Empleado: true,
    Imagen: ImgPagoPendiente,
    Registro: 0,
  },
  {
    id: 8,
    idName: "btnConPag",
    name: "Complemento de pago",
    Proveedor: true,
    Empleado: true,
    Imagen: ImgPago,
    Registro: 0,
  },
];
