import React from "react";
import PropTypes from "prop-types";
import { Navigate } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import {
  Box,
  Grid,
  Toolbar,
  Paper,
  Typography,
  AppBar,
  Tooltip,
  CircularProgress,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import RefreshIcon from "@mui/icons-material/Refresh";
import {
  theme,
  StyledSearch,
  StyledSearchIconWrapper,
  StyledInputBase,
  StyledButtonReload,
  StylePaperLoad,
} from "./CtaProveddores-css";
import Cookies from "js-cookie";
import { Header, MenuLateral } from "../Header/Header";
import { addProveedor } from "../Data/CtaProveedores-Data";
import CtaProveedoresTabla from "./CtaProveedoresTabla";
import { UpdateAccessToken } from "../Router/Cookies";

function CtaProveedores(props) {
  const [filtro, setFiltro] = React.useState("");
  const [cargar, setCargar] = React.useState(true);
  const { proveedores, setProveedores } = props;
  const usuario = Cookies.get("usuario");
  const operador = Cookies.get("operador");
  const id = Cookies.get("id");
  let IsSession = !!Cookies.get("access");
  const access = Cookies.get("access");

  React.useEffect(() => {
    fetchData(setProveedores);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = async (setProveedores) => {
    setProveedores([]);
    setCargar(true);
    const Values = {
      usuario: id,
      operador: operador,
      tipo: "Lista",
    };
    const Peticion = new Request("api/proveedores/rowProveedores", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-access-token": access,
      },
      method: "POST",
      body: JSON.stringify(Values),
    });

    let DatosArray = [];
    let DatosArrays = [];
    try {
      const response = await fetch(Peticion);
      const data = await response.json();
      const Datos = data.data;

      Datos.map((item) =>
        DatosArray.push(
          addProveedor(
            item.Fila,
            item.Fila,
            item.CardCode,
            item.U_SYS_PMTYPE === "F" ? "Fisica" : "Moral",
            item.CardName,
            item.LicTradNum,
            item.Phone1,
            item.E_Mail,
            item.CntctPrsn,
            item.U_SYS_PMPASS,
            item.U_SYS_PMESPR === "A" ? "Activo" : "Inactivo",
            item.U_SYS_PMESRE === "C"
              ? "Completo"
              : item.U_SYS_PMESRE === "P"
              ? "Pendiente"
              : "Rechazado",
            item.MOTRECHAZO,
            item.sociedades,
            // Datos del comprobante de la acta constitutiva
            item.U_SYS_PMCHAC,
            item.U_SYS_PMVIAC,
            item.U_SYS_PMACCO,
            item.U_SYS_PMFEAC,
            // Datos del comprobante de identificación
            item.U_SYS_PMCHIR,
            item.U_SYS_PMVIIR,
            item.U_SYS_PMIDOF,
            item.U_SYS_PMFEIO,
            // Datos del comprobante de domicilio
            item.U_SYS_PMCHCD,
            item.U_SYS_PMVICD,
            item.U_SYS_PMCODO,
            item.U_SYS_PMFECD,
            // Datos del comprobante de cedula de identificacion fiscal
            item.U_SYS_PMCHIF,
            item.U_SYS_PMVIIF,
            item.U_SYS_PMCEFI,
            item.U_SYS_PMFECF,

            // Datos del comprobante de cedula de Estado de cuenta
            item.U_SYS_PMCHES,
            item.U_SYS_PMVIES,
            item.U_SYS_PMECBA,
            item.U_SYS_PMFECB,

            // Datos del comprobante de cedula de Opinión de cumplimiento de obligación fiscal
            item.U_SYS_PMCHOF,
            item.U_SYS_PMVIOF,
            item.U_SYS_PMOPFI,
            item.U_SYS_PMFEOF,

            item.U_SYS_PMDOCA,
            item.U_SYS_PMFEDA
          )
        )
      );

      setProveedores(DatosArray);
    } catch (error) {
      console.error(error);
    }
    setCargar(false);
  };

  if (!UpdateAccessToken()) {
    return <Navigate to="/" />;
  }

  // const OnButtonClick = (event) => {
  //   if (event.target.id === "btnCrear") {
  //     navigate("/Catalogo-Proveedores/Proveedor/Nuevo");
  //   }
  // };

  const handleForma = (event) => {
    event.preventDefault(); //esto previene que el form se mande.
    fetchData(setProveedores);
  };

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          display: "flex",
        }}
      >
        <Header
          IsSession={IsSession}
          open={props.open}
          setOpen={props.setOpen}
          operador={operador}
          usuario={usuario}
        />
        <MenuLateral
          IsSession={IsSession}
          open={props.open}
          setOpen={props.setOpen}
        />
        <Box
          sx={{
            flexGrow: 10,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />
          <Typography
            color="text.secondary"
            align="center"
            variant="h4"
            component="h4"
            margin={2}
          >
            Catálogo de proveedor
          </Typography>
          <Paper sx={{ maxWidth: "90%", margin: "auto", overflow: "hidden" }}>
            <AppBar
              position="static"
              sx={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }}
            >
              <Toolbar>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs>
                    <StyledSearch>
                      <StyledSearchIconWrapper>
                        <SearchIcon />
                      </StyledSearchIconWrapper>
                      <StyledInputBase
                        placeholder="Search…"
                        inputProps={{ "aria-label": "search" }}
                        value={filtro}
                        onChange={(e) => setFiltro(e.target.value)}
                      />
                    </StyledSearch>
                  </Grid>
                  <Grid item xs align="right">
                    {/* <StyledButton id="btnCrear" onClick={OnButtonClick}>
                      Agregar
                    </StyledButton> */}
                    <Tooltip title="Recargar">
                      <StyledButtonReload
                        color="inherit"
                        onClick={handleForma}
                        size="medium"
                      >
                        <RefreshIcon
                          id="btnRefreshEmpleado"
                          color="inherit"
                          sx={{ display: "block" }}
                        />
                      </StyledButtonReload>
                    </Tooltip>
                  </Grid>
                </Grid>
              </Toolbar>
              <CtaProveedoresTabla filtro={filtro} rows={proveedores} />
              <StylePaperLoad>
                {cargar === true && <CircularProgress />}
              </StylePaperLoad>
            </AppBar>
          </Paper>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

CtaProveedores.propTypes = {
  proveedores: PropTypes.array,
};

export default CtaProveedores;
