export function addSociedad(
  id,
  Numero,
  RazonSocial,
  RFC,
  RegimenFiscal,
  CodigoPostal,
  Estatus,
  UsuarioSAP,
  ContraseñaSAP,
  BDSAP,
  CartaSituacionFiscalNombre,
  CartaSituacionFiscal
) {
  return {
    id,
    Numero,
    RazonSocial,
    RFC,
    RegimenFiscal,
    CodigoPostal,
    Estatus,
    UsuarioSAP,
    ContraseñaSAP,
    BDSAP,
    CartaSituacionFiscalNombre,
    CartaSituacionFiscal,
  };
}

export const CeldasSociedades = [
  {
    id: "#",
    align: "Left",
    disablePadding: true,
    label: "#",
  },
  {
    id: "RazonSocial",
    align: "Left",
    disablePadding: true,
    label: "Razón social",
  },
  {
    id: "RFC",
    align: "Left",
    disablePadding: true,
    label: "RFC",
  },
  {
    id: "RegimenFiscal",
    align: "Left",
    disablePadding: true,
    label: "Régimen fiscal",
  },
  {
    id: "CodigoPostal",
    align: "Left",
    disablePadding: true,
    label: "Código postal",
  },
  {
    id: "Estatus",
    align: "Left",
    disablePadding: true,
    label: "Estatus",
  },
  {
    id: "CartaSituacionFiscal",
    align: "Left",
    disablePadding: true,
    label: "Constancia de situación fiscal",
  },
];
