import React from "react";
import PropTypes from "prop-types";
import {} from "react-router-dom";
import { AppBar } from "@mui/material";

import TablaSociedades from "./TablaSociedades";

function TablaSociedadesDetalles(props) {
  const { sociedades, idProveedor } = props;

  return (
    <AppBar
      position="static"
      sx={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }}
    >
      {sociedades !== undefined &&
        sociedades !== "" &&
        sociedades.length > 0 && (
          <TablaSociedades rows={sociedades} idProveedor={idProveedor} />
        )}
    </AppBar>
  );
}

TablaSociedadesDetalles.propTypes = {
  sociedades: PropTypes.array,
};

export default TablaSociedadesDetalles;
