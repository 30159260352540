import React from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import { useNavigate, Navigate } from "react-router-dom";
import { Header, MenuLateral } from "../Header/Header";
import { Box, Toolbar, Typography, Grid, TextField } from "@mui/material";
import { isAuthenticatedvalid } from "../Router/Cookies";
import { ThemeProvider } from "@mui/material/styles";
import {
  theme,
  StylePaperPrincipal,
  StyledDiv,
  StylePaperButton,
  StyledButton,
} from "./Styled-Pedidos";
import Cookies from "js-cookie";
import { addPedido } from "../Data/Pedidos-Data";
import PedidosDetalle from "./Detalle/PedidosDetalle";
import { UpdateAccessToken } from "../Router/Cookies";
import createFile from "../Data/ArchivoBase64";
import { obtenerUsoSAT, obtenerMetodPagoSAT } from "../Data/DatosSAT";

function Pedido(props) {
  let params = useParams();

  const [values, setValues] = React.useState(
    addPedido("", "", "", "", "", "", "", "", "", "", "", "", "")
  );
  const { pedidos } = props;
  const [habilitar, setHabilitar] = React.useState(false);
  const navigate = useNavigate();
  let IsSession = isAuthenticatedvalid("access");
  let iRegistro = params.id - 1;

  UpdateAccessToken();

  React.useEffect(() => {
    if (pedidos.length > 0) {
      setValues({
        Sociedad: pedidos[iRegistro].Sociedad,
        NomProveedor: pedidos[iRegistro].NomProveedor,
        Pedido: pedidos[iRegistro].Pedido,
        EstatusAceptacion: pedidos[iRegistro].Estatus,
        Fecha: pedidos[iRegistro].Fecha,
        FechaVencimiento: pedidos[iRegistro].FechaVencimiento,
        DiasCredito: pedidos[iRegistro].DiasCredito,
        UsoPrincipal: obtenerUsoSAT(pedidos[iRegistro].UsoPrincipal),
        MetodoPago: obtenerMetodPagoSAT(pedidos[iRegistro].MetodoPago),
        Total: pedidos[iRegistro].Total,
        Referencia: pedidos[iRegistro].Referencia,
        Comentarios: pedidos[iRegistro].Comentarios,
        PDF: pedidos[iRegistro].PDF,
      });
      setHabilitar(true);
    } else {
      navigate("/Pedidos");
    }
  }, [pedidos, iRegistro, navigate]);

  function handleChange(evt) {
    const { target } = evt;
    const { name, value } = target;
    const newValues = {
      ...values,
      [name]: value,
    };
    setValues(newValues);
  }

  if (IsSession) {
    return (
      <ThemeProvider theme={theme}>
        <Box sx={{ display: "flex" }}>
          <Header
            IsSession={IsSession}
            open={props.open}
            setOpen={props.setOpen}
            operador={Cookies.get("operador")}
            usuario={Cookies.get("usuario")}
          />
          <MenuLateral
            IsSession={IsSession}
            open={props.open}
            setOpen={props.setOpen}
          />
          <Box
            component="main"
            sx={{
              flexGrow: 10,
              height: "100vh",
              overflow: "auto",
            }}
          >
            <Toolbar />
            <Typography
              sx={{ my: 3, mx: 2 }}
              align="center"
              variant="h5"
              component="h5"
            >
              Pedido #{values.Pedido}
            </Typography>

            {values.PDF && (
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <StylePaperButton variant="elevation" elevation={0}>
                  <StyledButton
                    type="submit"
                    variant="contained"
                    color="primary"
                    onClick={(e) => createFile(values.PDF, "application/pdf")}
                  >
                    Descargar PDF
                  </StyledButton>
                </StylePaperButton>
              </Grid>
            )}
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <StylePaperPrincipal variant="outlined">
                <StyledDiv>
                  <Typography
                    align="left"
                    variant="subtitle2"
                    gutterBottom
                    sx={window.innerWidth > 500 && { width: 1 / 2 }}
                  >
                    Sociedad:
                  </Typography>
                  <TextField
                    fullWidth
                    align="left"
                    autoComplete="section-pedidos-Sociedad"
                    size="small"
                    variant="outlined"
                    required
                    id="Sociedad"
                    name="Sociedad"
                    autoFocus
                    value={values.Sociedad}
                    disabled={habilitar}
                    onChange={handleChange}
                  />
                </StyledDiv>
                <StyledDiv>
                  <Typography
                    align="left"
                    variant="subtitle2"
                    gutterBottom
                    sx={window.innerWidth > 500 && { width: 1 / 2 }}
                  >
                    Proveedor:
                  </Typography>
                  <TextField
                    fullWidth
                    align="left"
                    autoComplete="section-pedidos-NomProveedor"
                    size="small"
                    variant="outlined"
                    required
                    id="NomProveedor"
                    name="NomProveedor"
                    autoFocus
                    value={values.NomProveedor}
                    disabled={habilitar}
                    onChange={handleChange}
                  />
                </StyledDiv>
                <StyledDiv>
                  <Typography
                    align="left"
                    variant="subtitle2"
                    gutterBottom
                    sx={window.innerWidth > 500 && { width: 1 / 2 }}
                  >
                    Fecha del pedido:
                  </Typography>
                  <TextField
                    fullWidth
                    align="left"
                    autoComplete="section-pedidos-Fecha"
                    size="small"
                    variant="outlined"
                    required
                    id="Fecha"
                    name="Fecha"
                    value={values.Fecha}
                    disabled={habilitar}
                    onChange={handleChange}
                  />
                </StyledDiv>
                <StyledDiv>
                  <Typography
                    align="left"
                    variant="subtitle2"
                    gutterBottom
                    sx={window.innerWidth > 500 && { width: 1 / 2 }}
                  >
                    Fecha de vencimiento:
                  </Typography>
                  <TextField
                    fullWidth
                    align="left"
                    autoComplete="section-pedidos-FechaVencimiento"
                    size="small"
                    variant="outlined"
                    required
                    id="FechaVencimiento"
                    name="FechaVencimiento"
                    value={values.FechaVencimiento}
                    disabled={habilitar}
                    onChange={handleChange}
                  />
                </StyledDiv>
                <StyledDiv>
                  <Typography
                    align="left"
                    variant="subtitle2"
                    gutterBottom
                    sx={window.innerWidth > 500 && { width: 1 / 2 }}
                  >
                    Referencia:
                  </Typography>
                  <TextField
                    fullWidth
                    align="left"
                    autoComplete="section-Empleado-Referencia"
                    size="small"
                    variant="outlined"
                    required
                    id="Referencia"
                    name="Referencia"
                    value={values.Referencia}
                    disabled={habilitar}
                    onChange={handleChange}
                  />
                </StyledDiv>
                <StyledDiv>
                  <Typography
                    align="left"
                    variant="subtitle2"
                    gutterBottom
                    sx={window.innerWidth > 500 && { width: 1 / 2 }}
                  >
                    Comentarios:
                  </Typography>
                  <TextField
                    multiline={true}
                    rows={3}
                    autoComplete="section-Empleado-Comentarios"
                    size="small"
                    fullWidth
                    variant="outlined"
                    required
                    id="Comentarios"
                    name="Comentarios"
                    value={values.Comentarios}
                    onChange={handleChange}
                    disabled={habilitar}
                  />
                </StyledDiv>
                <StyledDiv>
                  <Typography
                    align="left"
                    variant="subtitle2"
                    gutterBottom
                    sx={window.innerWidth > 500 && { width: 1 / 2 }}
                  >
                    Estatus:
                  </Typography>
                  <TextField
                    autoComplete="section-Empleado-EstatusAceptacion"
                    size="small"
                    fullWidth
                    variant="outlined"
                    required
                    id="EstatusAceptacion"
                    name="EstatusAceptacion"
                    value={values.EstatusAceptacion}
                    onChange={handleChange}
                    disabled={habilitar}
                  />
                </StyledDiv>
                <StyledDiv>
                  <Typography
                    align="left"
                    variant="subtitle2"
                    gutterBottom
                    sx={window.innerWidth > 500 && { width: 1 / 2 }}
                  >
                    Dias de credito:
                  </Typography>
                  <TextField
                    autoComplete="section-Empleado-DiasCredito"
                    size="small"
                    fullWidth
                    variant="outlined"
                    required
                    id="DiasCredito"
                    name="DiasCredito"
                    value={values.DiasCredito}
                    onChange={handleChange}
                    disabled={habilitar}
                  />
                </StyledDiv>
                <StyledDiv>
                  <Typography
                    align="left"
                    variant="subtitle2"
                    gutterBottom
                    sx={window.innerWidth > 500 && { width: 1 / 2 }}
                  >
                    Uso principal:
                  </Typography>
                  <TextField
                    autoComplete="section-Empleado-UsoPrincipal"
                    size="small"
                    fullWidth
                    variant="outlined"
                    required
                    id="UsoPrincipal"
                    name="UsoPrincipal"
                    value={values.UsoPrincipal}
                    onChange={handleChange}
                    disabled={habilitar}
                  />
                </StyledDiv>
                <StyledDiv>
                  <Typography
                    align="left"
                    variant="subtitle2"
                    gutterBottom
                    sx={window.innerWidth > 500 && { width: 1 / 2 }}
                  >
                    Método de pago
                  </Typography>
                  <TextField
                    autoComplete="section-Empleado-MetodoPago"
                    size="small"
                    fullWidth
                    variant="outlined"
                    required
                    id="MetodoPago"
                    name="MetodoPago"
                    value={values.MetodoPago}
                    onChange={handleChange}
                    disabled={habilitar}
                  />
                </StyledDiv>
              </StylePaperPrincipal>
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              {pedidos.length > 0 && (
                <PedidosDetalle Pedido={pedidos[iRegistro]} />
              )}
            </Grid>
          </Box>
        </Box>
      </ThemeProvider>
    );
  } else {
    return <Navigate to="/" />;
  }
}

Pedido.propTypes = {
  pedidos: PropTypes.array,
};

export default Pedido;
