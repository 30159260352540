import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import {
  TableContainer,
  alpha,
  Table,
  Paper,
  Toolbar,
  Typography,
  Tooltip,
  TableBody,
  TableRow,
  TableCell,
  Checkbox,
  TablePagination,
  TableHead,
  TableSortLabel,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { visuallyHidden } from "@mui/utils";
import { StyledButtonEditar } from "../CtaProveddores-css";
import { CeldasSociedades } from "../../Data/CtaSociedades-Data";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { ObtenerRegimenFiscal } from "../../Data/DatosSAT";
//Funcion para filtrar por la busqueda
function Ordenamiento(array, comparator, Filter) {
  const rows = [];

  array.forEach((Sociedad) => {
    rows.push(Sociedad);
  });

  const stabilizedThis = rows.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function getComparador(order, orderBy) {
  return order === "desc"
    ? (a, b) => ComparadorDecendiente(a, b, orderBy)
    : (a, b) => -ComparadorDecendiente(a, b, orderBy);
}

function ComparadorDecendiente(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function EncabezadoDatos(props) {
  const { order, orderBy, onRequestSort } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {CeldasSociedades.map((headCell) => (
          <TableCell
            typeof="Password"
            key={headCell.id}
            size="small"
            align={headCell.numeric}
            padding="normal"
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{ minWidth: headCell.minWidth }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function TablaSociedades(props) {
  console.log(props.rows);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("#");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [regimen, setRegimen] = React.useState("");

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selected.length > 0) {
      if (selectedIndex !== 0) {
        return;
      }
    }
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - props.rows.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      Ordenamiento(
        props.rows,
        getComparador(order, orderBy),
        props.filtro
      ).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [order, orderBy, page, rowsPerPage, props.filtro, props.rows]
  );

  const b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  };

  const createFile = (b64Data) => {
    const blob = b64toBlob(b64Data, "application/pdf");
    var fileURL = URL.createObjectURL(blob);
    window.open(fileURL);
    //saveAs(blob, "mi-archivo.pdf");
  };

  const handleChangeRegimenFiscal = (event, id) => {
    setRegimen(ObtenerRegimenFiscal(id));
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%" }}>
        <TableContainer>
          <Table>
            <EncabezadoDatos
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={props.rows.length}
              key="Proveedor-Sociedades"
            />
            <TableBody>
              {visibleRows.map((row, index) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    selected={isItemSelected}
                    sx={{ cursor: "pointer" }}
                  >
                    <TableCell
                      component="th"
                      id={labelId}
                      key={labelId}
                      scope="row"
                      padding="normal"
                    >
                      {row.Numero}
                    </TableCell>
                    <TableCell padding="normal">{row.NomSociedad}</TableCell>
                    <TableCell padding="normal">{row.RFC}</TableCell>
                    <TableCell
                      padding="normal"
                      onMouseOver={(event) =>
                        handleChangeRegimenFiscal(event, row.Regimen)
                      }
                    >
                      <Tooltip title={regimen}>{row.Regimen}</Tooltip>
                    </TableCell>
                    <TableCell padding="normal" type="password">
                      {row.CP}
                    </TableCell>
                    <TableCell padding="normal">
                      {row.Estatus === "A" ? "Activo" : "Inactivo"}
                    </TableCell>
                    <TableCell padding="normal">
                      <StyledButtonEditar
                        size="medium"
                        component="label"
                        role={undefined}
                        tabIndex={-1}
                        endIcon={<PictureAsPdfIcon />}
                        id="actaContitutiva"
                        name="actaContitutiva"
                        onClick={(e) => createFile(row.Constancia)}
                      >
                        Visualizar
                      </StyledButtonEditar>
                    </TableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 53 * emptyRows,
                  }}
                ></TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[
            5,
            10,
            15,
            { value: props.rows.length, label: "All" },
          ]}
          component="div"
          count={props.rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}

TablaSociedades.propTypes = {
  row: PropTypes.object,
};

export default TablaSociedades;
