import React from "react";
import PropTypes from "prop-types";
import Cookies from "js-cookie";
import { Navigate } from "react-router-dom";
import { UpdateAccessToken } from "../Router/Cookies";
import Proveedor from "../CtaProveedores/Proveedor";
import Empleado from "../CtaEmpleados/Empleado";
import { addProveedor } from "../Data/CtaProveedores-Data";
import { addEmpleado } from "../Data/CtaEmpleados-Data";
function Perfil(props) {
  const { setTipoPerfil, tipoPerfil, datos, setDatos } = props;
  const usuario = Cookies.get("usuario");
  const operador = Cookies.get("operador");
  const id = Cookies.get("id");
  const access = Cookies.get("access");

  React.useEffect(() => {
    if (operador === "Proveedor") {
      fetchDataProveedor(setDatos);
    } else {
      fetchDataEmpleado(setDatos);
    }
    setTipoPerfil(true);
  }, [setDatos]);

  const fetchDataProveedor = async (setDatos) => {
    const Values = {
      usuario: id,
      operador: operador,
      tipo: "Perfil",
    };
    const Peticion = new Request("api/proveedores/rowProveedores", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-access-token": access,
      },
      method: "POST",
      body: JSON.stringify(Values),
    });

    let DatosArray = [];
    try {
      const response = await fetch(Peticion);
      const data = await response.json();
      const Datos = data.data;

      Datos.map((item) =>
        DatosArray.push(
          addProveedor(
            item.Fila,
            item.Fila,
            item.CardCode,
            item.U_SYS_PMTYPE === "F" ? "Fisica" : "Moral",
            item.CardName,
            item.LicTradNum,
            item.Phone1,
            item.E_Mail,
            item.CntctPrsn,
            item.U_SYS_PMPASS,
            item.U_SYS_PMESPR === "A" ? "Activo" : "Inactivo",
            item.U_SYS_PMESRE === "C"
              ? "Completo"
              : item.U_SYS_PMESRE === "P"
              ? "Pendiente"
              : "Rechazado",
            item.MOTRECHAZO,
            item.sociedades,
            // Datos del comprobante de la acta constitutiva
            item.U_SYS_PMCHAC,
            item.U_SYS_PMVIAC,
            item.U_SYS_PMACCO,
            item.U_SYS_PMFEAC,
            // Datos del comprobante de identificación
            item.U_SYS_PMCHIR,
            item.U_SYS_PMVIIR,
            item.U_SYS_PMIDOF,
            item.U_SYS_PMFEIO,
            // Datos del comprobante de domicilio
            item.U_SYS_PMCHCD,
            item.U_SYS_PMVICD,
            item.U_SYS_PMCODO,
            item.U_SYS_PMFECD,
            // Datos del comprobante de cedula de identificacion fiscal
            item.U_SYS_PMCHIF,
            item.U_SYS_PMVIIF,
            item.U_SYS_PMCEFI,
            item.U_SYS_PMFECF,

            // Datos del comprobante de cedula de Estado de cuenta
            item.U_SYS_PMCHES,
            item.U_SYS_PMVIES,
            item.U_SYS_PMECBA,
            item.U_SYS_PMFECB,

            // Datos del comprobante de cedula de Opinión de cumplimiento de obligación fiscal
            item.U_SYS_PMCHOF,
            item.U_SYS_PMVIOF,
            item.U_SYS_PMOPFI,
            item.U_SYS_PMFEOF,

            item.U_SYS_PMDOCA,
            item.U_SYS_PMFEDA
          )
        )
      );

      setDatos(DatosArray);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchDataEmpleado = async (setDatos) => {
    const Values = {
      usuario: id,
      operador: operador,
      tipo: "Perfil",
    };
    const Peticion = new Request("api/empleados/rowEmpleados", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-access-token": access,
      },
      method: "POST",
      body: JSON.stringify(Values),
    });

    let DatosArray = [];
    try {
      const response = await fetch(Peticion);
      const data = await response.json();
      const Datos = data.data;

      Datos.map((item) =>
        DatosArray.push(
          addEmpleado(
            item.Fila,
            item.IdEmpleado,
            item.Perfil,
            item.Nombre,
            item.Apellidos,
            item.Email,
            item.IdArea,
            item.Area,
            item.Estatus === "A" ? "Activo" : "Inactivo",
            item.Pass,
            id
          )
        )
      );

      setDatos(DatosArray);
    } catch (error) {
      console.error(error);
    }
  };

  if (!UpdateAccessToken()) {
    return <Navigate to="/" />;
  }

  if (operador === "Proveedor") {
    return (
      <Proveedor
        setOpen={props.setOpen}
        open={props.open}
        usuario={usuario}
        proveedores={datos}
        tipoPerfil={tipoPerfil}
      />
    );
  } else {
    return (
      <Empleado
        setOpen={props.setOpen}
        open={props.open}
        usuario={usuario}
        empleados={datos}
        tipoPerfil={tipoPerfil}
      />
    );
  }
}

Perfil.propTypes = {
  usuario: PropTypes.string,
};

export default Perfil;
