import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import {
  TableContainer,
  Table,
  Paper,
  TableBody,
  TableRow,
  TableCell,
  Checkbox,
  TablePagination,
  TableHead,
  Tooltip,
  TextField,
  Select,
  MenuItem,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { visuallyHidden } from "@mui/utils";
import { StyledButtonEditar } from "./Styled-FacturasPendientes";
import { CeldasFacturasPendientes } from "../Data/FacturasPendientes-Data";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import DescriptionIcon from "@mui/icons-material/Description";
import {
  obtenerUsoSAT,
  obtenerMetodPagoSAT,
  obtenerFormaDePago,
} from "../Data/DatosSAT";

//Funcion para filtrar por la busqueda
function Ordenamiento(array, comparator, Filter) {
  const rows = [];
  array.forEach((Factura) => {
    if (
      Factura.Numero !== undefined &&
      Factura.Numero !== "" &&
      Factura.Numero !== null &&
      Factura.Numero.toString().toLowerCase().indexOf(Filter.toLowerCase()) !==
        -1
    ) {
      rows.push(Factura);
    } else if (
      Factura.Sociedad !== undefined &&
      Factura.Sociedad !== "" &&
      Factura.Sociedad !== null &&
      Factura.Sociedad.toLowerCase().indexOf(Filter.toLowerCase()) !== -1
    ) {
      rows.push(Factura);
    } else if (
      Factura.NomProveedor !== undefined &&
      Factura.NomProveedor !== "" &&
      Factura.NomProveedor !== null &&
      Factura.NomProveedor.toLowerCase().indexOf(Filter.toLowerCase()) !== -1
    ) {
      rows.push(Factura);
    } else if (
      Factura.Codigo !== undefined &&
      Factura.Codigo !== "" &&
      Factura.Codigo !== null &&
      Factura.Codigo.toString().toLowerCase().indexOf(Filter.toLowerCase()) !==
        -1
    ) {
      rows.push(Factura);
    } else if (
      Factura.RFC !== undefined &&
      Factura.RFC !== "" &&
      Factura.RFC !== null &&
      Factura.RFC.toString().toLowerCase().indexOf(Filter.toLowerCase()) !== -1
    ) {
      rows.push(Factura);
    } else if (
      Factura.UUID !== undefined &&
      Factura.UUID !== "" &&
      Factura.UUID !== null &&
      Factura.UUID.toString().toLowerCase().indexOf(Filter.toLowerCase()) !== -1
    ) {
      rows.push(Factura);
    } else if (
      Factura.Entrada !== undefined &&
      Factura.Entrada !== "" &&
      Factura.Entrada !== null &&
      Factura.Entrada.toString().toLowerCase().indexOf(Filter.toLowerCase()) !==
        -1
    ) {
      rows.push(Factura);
    } else if (
      Factura.Fecha !== undefined &&
      Factura.Fecha !== "" &&
      Factura.Fecha !== null &&
      Factura.Fecha.toString().toLowerCase().indexOf(Filter.toLowerCase()) !==
        -1
    ) {
      rows.push(Factura);
    } else if (
      Factura.Total !== undefined &&
      Factura.Total !== "" &&
      Factura.Total !== null &&
      Factura.Total.toString().toLowerCase().indexOf(Filter.toLowerCase()) !==
        -1
    ) {
      rows.push(Factura);
    } else if (
      Factura.FormaPago !== undefined &&
      Factura.FormaPago !== "" &&
      Factura.FormaPago !== null &&
      Factura.FormaPago.toString()
        .toLowerCase()
        .indexOf(Filter.toLowerCase()) !== -1
    ) {
      rows.push(Factura);
    } else if (
      Factura.UsoPrincipal !== undefined &&
      Factura.UsoPrincipal !== "" &&
      Factura.UsoPrincipal !== null &&
      Factura.UsoPrincipal.toString()
        .toLowerCase()
        .indexOf(Filter.toLowerCase()) !== -1
    ) {
      rows.push(Factura);
    } else if (
      Factura.MetodoPago !== undefined &&
      Factura.MetodoPago !== "" &&
      Factura.MetodoPago !== null &&
      Factura.MetodoPago.toString()
        .toLowerCase()
        .indexOf(Filter.toLowerCase()) !== -1
    ) {
      rows.push(Factura);
    } else if (
      Factura.UsoPrincipal !== undefined &&
      Factura.UsoPrincipal !== "" &&
      Factura.UsoPrincipal !== null &&
      Factura.UsoPrincipal.toString()
        .toLowerCase()
        .indexOf(Filter.toLowerCase()) !== -1
    ) {
      rows.push(Factura);
    } else if (
      Factura.TentativaPago !== undefined &&
      Factura.TentativaPago !== "" &&
      Factura.TentativaPago !== null &&
      Factura.TentativaPago.toString()
        .toLowerCase()
        .indexOf(Filter.toLowerCase()) !== -1
    ) {
      rows.push(Factura);
    } else if (
      Factura.Estatus !== undefined &&
      Factura.Estatus !== "" &&
      Factura.Estatus !== null &&
      Factura.Estatus.toString().toLowerCase().indexOf(Filter.toLowerCase()) !==
        -1
    ) {
      rows.push(Factura);
    }
  });

  const stabilizedThis = rows.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function getComparador(order, orderBy) {
  return order === "desc"
    ? (a, b) => ComparadorDecendiente(a, b, orderBy)
    : (a, b) => -ComparadorDecendiente(a, b, orderBy);
}

function ComparadorDecendiente(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function EncabezadoDatos(props) {
  const { order, orderBy } = props;

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox"></TableCell>
        {CeldasFacturasPendientes.map((headCell) => (
          <TableCell
            typeof="Password"
            key={headCell.id}
            size="small"
            align={headCell.numeric ? "right" : "center"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{ minWidth: headCell.minWidth }}
          >
            {headCell.label}
            {orderBy === headCell.id ? (
              <Box component="span" sx={visuallyHidden}>
                {order === "desc" ? "sorted descending" : "sorted ascending"}
              </Box>
            ) : null}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function FacturasPendientesTabla(props) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("#");
  const { selected, setSelected } = props;
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [usoPrincipal, setUsoPrincipal] = React.useState("");
  const [metodoPago, setMetodoPago] = React.useState("");
  const [formaPago, setFormaPago] = React.useState("");

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    const NewRows = props.rows.map((row) => {
      if (row.id === id) {
        return {
          ...row,
          Revisado: !row.Revisado,
        };
      } else {
        return row;
      }
    });
    props.setRows(NewRows);
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - props.rows.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      Ordenamiento(
        props.rows,
        getComparador(order, orderBy),
        props.filtro
      ).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [order, orderBy, page, rowsPerPage, props.filtro, props.rows]
  );

  const b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  };

  const createFile = (event, b64Data) => {
    let fileURL;
    switch (event.target.id) {
      case "PDF":
        const blobpdf = b64toBlob(b64Data, "application/pdf");
        fileURL = URL.createObjectURL(blobpdf);
        break;
      case "XML":
        const blobxml = b64toBlob(b64Data, "text/xml");
        fileURL = URL.createObjectURL(blobxml);
        break;
      case "Recepcion":
        const blobrecepcion = b64toBlob(
          b64Data,
          "image/gif, image/jpeg, image/png"
        );
        fileURL = URL.createObjectURL(blobrecepcion);
        break;
      default:
      // code block
    }

    window.open(fileURL);
  };

  function handleChange(evt, id) {
    const { target } = evt;
    const { name, value } = target;
    const NewRows = props.rows.map((row) => {
      if (row.id === id) {
        return {
          ...row,
          [name]: value,
        };
      } else {
        return row;
      }
    });
    props.setRows(NewRows);
  }

  const handleChangeUso = (event, id) => {
    setUsoPrincipal(obtenerUsoSAT(id));
  };

  const handleChangeMetoPago = (event, id) => {
    setMetodoPago(obtenerMetodPagoSAT(id));
  };

  const handleChangeFormaPago = (event, id) => {
    setFormaPago(obtenerFormaDePago(id));
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%" }}>
        <TableContainer>
          <Table>
            <EncabezadoDatos
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={props.rows.length}
            />
            <TableBody>
              {visibleRows.map((row, index) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    sx={{ cursor: "pointer" }}
                  >
                    <TableCell padding="checkbox"></TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      key={labelId}
                      scope="row"
                    >
                      {row.Numero}
                    </TableCell>
                    <TableCell padding="checkbox" align="center">
                      {row.Sociedad}
                    </TableCell>
                    <TableCell padding="checkbox" align="center">
                      {row.NomProveedor}
                    </TableCell>
                    <TableCell padding="checkbox" align="center">
                      {row.Codigo}
                    </TableCell>
                    <TableCell padding="checkbox" align="center">
                      {row.RFC}
                    </TableCell>
                    <TableCell padding="checkbox" align="center">
                      {row.UUID}
                    </TableCell>
                    <TableCell padding="checkbox" align="center">
                      {row.Entrada}
                    </TableCell>
                    <TableCell padding="checkbox" align="center">
                      {row.Fecha}
                    </TableCell>
                    <TableCell padding="checkbox" align="center">
                      {row.Total + " " + row.Moneda}
                    </TableCell>
                    <TableCell
                      padding="checkbox"
                      align="center"
                      onMouseOver={(event) =>
                        handleChangeFormaPago(event, row.FormaPago)
                      }
                    >
                      <Tooltip title={formaPago}>{row.FormaPago}</Tooltip>
                    </TableCell>

                    <TableCell
                      padding="checkbox"
                      align="center"
                      onMouseOver={(event) =>
                        handleChangeUso(event, row.UsoPrincipal)
                      }
                    >
                      <Tooltip title={usoPrincipal}>{row.UsoPrincipal}</Tooltip>
                    </TableCell>
                    <TableCell
                      padding="checkbox"
                      align="center"
                      onMouseOver={(event) =>
                        handleChangeMetoPago(event, row.MetodoPago)
                      }
                    >
                      <Tooltip title={metodoPago}>{row.MetodoPago}</Tooltip>
                    </TableCell>
                    <TableCell padding="checkbox" align="center">
                      {row.TentativaPago}
                    </TableCell>
                    <TableCell padding="checkbox" align="center">
                      <StyledButtonEditar
                        size="medium"
                        component="label"
                        role={undefined}
                        tabIndex={-1}
                        endIcon={<PictureAsPdfIcon />}
                        id="PDF"
                        name="PDF"
                        onClick={(e) => createFile(e, row.PDF)}
                      >
                        Visualizar
                      </StyledButtonEditar>
                    </TableCell>
                    <TableCell padding="checkbox" align="center">
                      <StyledButtonEditar
                        size="medium"
                        component="label"
                        role={undefined}
                        tabIndex={-1}
                        endIcon={<DescriptionIcon />}
                        id="XML"
                        name="XML"
                        onClick={(e) => createFile(e, row.XML)}
                      >
                        Visualizar
                      </StyledButtonEditar>
                    </TableCell>
                    <TableCell padding="checkbox" align="center">
                      <StyledButtonEditar
                        size="medium"
                        component="label"
                        role={undefined}
                        tabIndex={-1}
                        endIcon={<PictureAsPdfIcon />}
                        id="Recepcion"
                        name="Recepcion"
                        onClick={(e) => createFile(e, row.Recepcion)}
                      >
                        Visualizar
                      </StyledButtonEditar>
                    </TableCell>

                    <TableCell padding="checkbox" align="center">
                      <Select
                        autoComplete="section-sociedades-Estatus"
                        size="small"
                        fullWidth
                        align="left"
                        variant="outlined"
                        required
                        id="Estatus"
                        name="Estatus"
                        value={row.Estatus ? row.Estatus : "P"}
                        disabled={row.EstatusBD === "A" ? true : false}
                        onChange={(event) => handleChange(event, row.id)}
                      >
                        <MenuItem id="area-A" value="P">
                          Pendiente
                        </MenuItem>
                        <MenuItem id="area-I" value="A">
                          Aceptada
                        </MenuItem>
                        <MenuItem id="area-I" value="R">
                          Rechazada
                        </MenuItem>
                      </Select>
                    </TableCell>

                    <TableCell padding="checkbox" align="center">
                      <Checkbox
                        id="Revisado"
                        name="Revisado"
                        onClick={(event) => handleClick(event, row.id)}
                        color="primary"
                        checked={row.Revisado}
                        inputProps={{
                          "aria-labelledby": labelId,
                        }}
                        disabled={row.EstatusBD === "A" ? true : false}
                      />
                    </TableCell>
                    <TableCell padding="checkbox" align="center">
                      <TextField
                        fullWidth
                        align="left"
                        autoComplete="section-facturasPendientes-Comentarios"
                        size="small"
                        variant="outlined"
                        required
                        id="Comentarios"
                        name="Comentarios"
                        autoFocus
                        value={row.Comentarios}
                        multiline={true}
                        rows={3}
                        disabled={row.EstatusBD === "A" ? true : false}
                        onChange={(event) => handleChange(event, row.id)}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 53 * emptyRows,
                  }}
                ></TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[
            5,
            10,
            15,
            { value: props.rows.length, label: "All" },
          ]}
          component="div"
          count={props.rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}

FacturasPendientesTabla.propTypes = {
  row: PropTypes.object,
};

export default FacturasPendientesTabla;
