export const b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

export const createFile = (b64Data, Type) => {
  const blob = b64toBlob(b64Data, Type);
  var fileURL = URL.createObjectURL(blob);
  console.log(fileURL);
  window.open(fileURL);

  // var fileLink = document.createElement("a");
  // fileLink.href = fileURL;
  // fileLink.download = "Carta-Situasión-Fiscal.pdf";
  // fileLink.click();

  //saveAs(blob, "mi-archivo.pdf");
};

export const convertToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      resolve(reader.result);
    };

    reader.onerror = (error) => {
      reject(error);
    };

    reader.readAsDataURL(file);
  });
};

export default createFile;
