import React from "react";
import PropTypes from "prop-types";
import { useNavigate, Navigate } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import {
  Box,
  Grid,
  Toolbar,
  Paper,
  Typography,
  AppBar,
  Tooltip,
  CircularProgress,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import RefreshIcon from "@mui/icons-material/Refresh";
import {
  theme,
  StyledSearch,
  StyledSearchIconWrapper,
  StyledInputBase,
  StyledButton,
  StyledButtonReload,
  StylePaperLoad,
} from "./CtaSociedades-css";
import Cookies from "js-cookie";
import { Header, MenuLateral } from "../Header/Header";
import { addSociedad } from "../Data/CtaSociedades-Data";
import CtaSociedadesTabla from "./CtaSociedadesTabla";
import { UpdateAccessToken } from "../Router/Cookies";

function CtaSociedades(props) {
  const navigate = useNavigate();
  const [filtro, setFiltro] = React.useState("");
  const [habilitar, setHabilitar] = React.useState(false);
  const [cargar, setCargar] = React.useState(true);
  const { sociedades, setSociedades } = props;
  const usuario = Cookies.get("usuario");
  const operador = Cookies.get("operador");
  const access = Cookies.get("access");
  const tipoEmpleado = Cookies.get("tipoEmpleado");
  const id = Cookies.get("id");

  let IsSession = !!Cookies.get("access");

  React.useEffect(() => {
    setSociedades([]);
    fetchData(setSociedades);
    if (operador === "Empleado" && tipoEmpleado === "Administrador") {
      setHabilitar(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = async (setSociedades) => {
    setSociedades([]);
    setCargar(true);
    const Values = {
      usuario: id,
      operador: operador,
    };
    const Peticion = new Request("api/sociedades/rowSociedades", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-access-token": access,
      },
      method: "POST",
      body: JSON.stringify(Values),
    });

    let DatosArray = [];
    try {
      const response = await fetch(Peticion);
      const data = await response.json();
      const Datos = data.data;

      Datos.map((item) =>
        DatosArray.push(
          addSociedad(
            item.Fila,
            item.IdSociedad,
            item.NomSociedad,
            item.RFC,
            item.Regimen,
            item.CP,
            item.Estatus === "A" ? "Activo" : "Inactivo",
            item.UsuarioSAP,
            item.PassSAP,
            item.BDSAP,
            "Nombre.pdf",
            item.Constancia ? item.Constancia : ""
          )
        )
      );

      setSociedades(DatosArray);
    } catch (error) {
      console.log(error);
    }
    setCargar(false);
  };

  if (!UpdateAccessToken()) {
    return <Navigate to="/" />;
  }

  const handleForma = (event) => {
    event.preventDefault();
    fetchData(setSociedades);
  };

  const OnButtonClick = (event) => {
    if (event.target.id === "btnCrear") {
      navigate("/Catalogo-Sociedades/Sociedad/Nuevo");
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          display: "flex",
        }}
      >
        <Header
          IsSession={IsSession}
          open={props.open}
          setOpen={props.setOpen}
          operador={operador}
          usuario={usuario}
        />
        <MenuLateral
          IsSession={IsSession}
          open={props.open}
          setOpen={props.setOpen}
        />
        <Box
          sx={{
            flexGrow: 10,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />

          <Typography
            color="text.secondary"
            align="center"
            variant="h4"
            component="h4"
            margin={2}
          >
            Catálogo de sociedades
          </Typography>
          <Paper sx={{ maxWidth: "90%", margin: "auto", overflow: "hidden" }}>
            <AppBar
              position="static"
              sx={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }}
            >
              <Toolbar>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs>
                    <StyledSearch>
                      <StyledSearchIconWrapper>
                        <SearchIcon />
                      </StyledSearchIconWrapper>
                      <StyledInputBase
                        placeholder="Search…"
                        inputProps={{ "aria-label": "search" }}
                        value={filtro}
                        onChange={(e) => setFiltro(e.target.value)}
                      />
                    </StyledSearch>
                  </Grid>
                  <Grid>
                    {habilitar === true && (
                      <StyledButton id="btnCrear" onClick={OnButtonClick}>
                        Agregar
                      </StyledButton>
                    )}
                    <Tooltip title="Recargar">
                      <StyledButtonReload
                        color="inherit"
                        onClick={handleForma}
                        size="medium"
                      >
                        <RefreshIcon
                          id="btnRefreshEmpleado"
                          color="inherit"
                          sx={{ display: "block" }}
                        />
                      </StyledButtonReload>
                    </Tooltip>
                  </Grid>
                </Grid>
              </Toolbar>
              <CtaSociedadesTabla filtro={filtro} rows={sociedades} />
              <StylePaperLoad>
                {cargar === true && <CircularProgress />}
              </StylePaperLoad>
            </AppBar>
          </Paper>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

CtaSociedades.propTypes = {
  sociedades: PropTypes.array,
};

export default CtaSociedades;
