
import { createTheme } from "@mui/material/styles";
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { Button } from "@mui/material";
import { yellow, blue } from "@mui/material/colors";

export const theme = createTheme();

export const StyledPaper = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    borderColor: '#2a00e6',
    marginRight: theme.spacing(10),
    marginTrim: theme.spacing(2),
    margin: theme.spacing(1, 1, 1),

}));

export const StyledButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(yellow[500]),
    backgroundColor: yellow[800],
    "&:hover": {
        backgroundColor: blue[800],
    },
    marginTop: theme.spacing(2),
}));

export const Styleddiv = styled("div")(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    marginTop: theme.spacing(1),
}));

