import React from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import { useNavigate, Navigate } from "react-router-dom";
import { Header, MenuLateral } from "../Header/Header";
import { Box, Toolbar, Typography, Grid, TextField } from "@mui/material";
import { isAuthenticatedvalid } from "../Router/Cookies";
import { ThemeProvider } from "@mui/material/styles";
import {
  theme,
  StylePaperPrincipal,
  StyledDiv,
  StylePaperButton,
  StyledButton,
} from "./Styled-EntradaMercancias";
import Cookies from "js-cookie";
import { addEntradaMercancia } from "../Data/EntradasMercancia-Data";
import EntradaMercanciasDetalle from "./Detalles/EntradaMercanciasDetalle";
import { UpdateAccessToken } from "../Router/Cookies";
import createFile from "../Data/ArchivoBase64";
import { obtenerUsoSAT, obtenerMetodPagoSAT } from "../Data/DatosSAT";

function EntradaMercancia(props) {
  let params = useParams();

  const [values, setValues] = React.useState(
    addEntradaMercancia("", "", "", "", "", "", "", "", "", "", "", "")
  );
  const { entradaMercancias } = props;
  const [habilitar, setHabilitar] = React.useState(false);
  const navigate = useNavigate();
  let IsSession = isAuthenticatedvalid("access");
  let iRegistro = params.id - 1;

  UpdateAccessToken();

  React.useEffect(() => {
    if (entradaMercancias.length > 0) {
      setValues({
        Sociedad: entradaMercancias[iRegistro].Sociedad,
        NomProveedor: entradaMercancias[iRegistro].NomProveedor,
        Entrada: entradaMercancias[iRegistro].Entrada,
        Pedido: entradaMercancias[iRegistro].Pedido,
        Estatus: entradaMercancias[iRegistro].Estatus,
        Fecha: entradaMercancias[iRegistro].Fecha,
        FechaVencimiento: entradaMercancias[iRegistro].FechaVencimiento,
        DiasCredito: entradaMercancias[iRegistro].DiasCredito,
        UsoPrincipal: obtenerUsoSAT(entradaMercancias[iRegistro].UsoPrincipal),
        MetodoPago: obtenerMetodPagoSAT(
          entradaMercancias[iRegistro].MetodoPago
        ),
        Total: entradaMercancias[iRegistro].Total,
        Referencia: entradaMercancias[iRegistro].Referencia,
        Comentarios: entradaMercancias[iRegistro].Comentarios,
        PDF: entradaMercancias[iRegistro].PDF,
      });
      setHabilitar(true);
    } else {
      navigate("/Entradas-Mercancia");
    }
  }, [entradaMercancias, iRegistro, navigate]);

  function handleChange(evt) {
    const { target } = evt;
    const { name, value } = target;
    const newValues = {
      ...values,
      [name]: value,
    };
    setValues(newValues);
  }

  if (IsSession) {
    return (
      <ThemeProvider theme={theme}>
        <Box sx={{ display: "flex" }}>
          <Header
            IsSession={IsSession}
            open={props.open}
            setOpen={props.setOpen}
            operador={Cookies.get("operador")}
            usuario={Cookies.get("usuario")}
          />
          <MenuLateral
            IsSession={IsSession}
            open={props.open}
            setOpen={props.setOpen}
          />
          <Box
            component="main"
            sx={{
              flexGrow: 10,
              height: "100vh",
              overflow: "auto",
            }}
          >
            <Toolbar />
            <Typography
              sx={{ my: 3, mx: 2 }}
              align="center"
              variant="h5"
              component="h5"
            >
              Entrada de mercancía #{values.Entrada}
            </Typography>

            {values.PDF && (
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <StylePaperButton variant="elevation" elevation={0}>
                  <StyledButton
                    type="submit"
                    variant="contained"
                    color="primary"
                    onClick={(e) => createFile(values.PDF, "application/pdf")}
                  >
                    Descargar PDF
                  </StyledButton>
                </StylePaperButton>
              </Grid>
            )}
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <StylePaperPrincipal variant="outlined">
                <StyledDiv>
                  <Typography
                    align="left"
                    variant="subtitle2"
                    gutterBottom
                    sx={window.innerWidth > 500 && { width: 1 / 2 }}
                  >
                    Pedido:
                  </Typography>
                  <TextField
                    fullWidth
                    align="left"
                    autoComplete="section-Entrada-Pedido"
                    size="small"
                    variant="outlined"
                    required
                    id="Pedido"
                    name="Pedido"
                    autoFocus
                    value={values.Pedido}
                    disabled={habilitar}
                    onChange={handleChange}
                  />
                </StyledDiv>
                <StyledDiv>
                  <Typography
                    align="left"
                    variant="subtitle2"
                    gutterBottom
                    sx={window.innerWidth > 500 && { width: 1 / 2 }}
                  >
                    Sociedad:
                  </Typography>
                  <TextField
                    fullWidth
                    align="left"
                    autoComplete="section-Entrada-Sociedad"
                    size="small"
                    variant="outlined"
                    required
                    id="Sociedad"
                    name="Sociedad"
                    autoFocus
                    value={values.Sociedad}
                    disabled={habilitar}
                    onChange={handleChange}
                  />
                </StyledDiv>
                <StyledDiv>
                  <Typography
                    align="left"
                    variant="subtitle2"
                    gutterBottom
                    sx={window.innerWidth > 500 && { width: 1 / 2 }}
                  >
                    Proveedor:
                  </Typography>
                  <TextField
                    fullWidth
                    align="left"
                    autoComplete="section-Entrada-NomProveedor"
                    size="small"
                    variant="outlined"
                    required
                    id="NomProveedor"
                    name="NomProveedor"
                    autoFocus
                    value={values.NomProveedor}
                    disabled={habilitar}
                    onChange={handleChange}
                  />
                </StyledDiv>
                <StyledDiv>
                  <Typography
                    align="left"
                    variant="subtitle2"
                    gutterBottom
                    sx={window.innerWidth > 500 && { width: 1 / 2 }}
                  >
                    Fecha de la entrada:
                  </Typography>
                  <TextField
                    fullWidth
                    align="left"
                    autoComplete="section-Entrada-Fecha"
                    size="small"
                    variant="outlined"
                    required
                    id="Fecha"
                    name="Fecha"
                    value={values.Fecha}
                    disabled={habilitar}
                    onChange={handleChange}
                  />
                </StyledDiv>
                <StyledDiv>
                  <Typography
                    align="left"
                    variant="subtitle2"
                    gutterBottom
                    sx={window.innerWidth > 500 && { width: 1 / 2 }}
                  >
                    Fecha de vencimiento:
                  </Typography>
                  <TextField
                    fullWidth
                    align="left"
                    autoComplete="section-Entrada-FechaVencimiento"
                    size="small"
                    variant="outlined"
                    required
                    id="FechaVencimiento"
                    name="FechaVencimiento"
                    value={values.FechaVencimiento}
                    disabled={habilitar}
                    onChange={handleChange}
                  />
                </StyledDiv>
                <StyledDiv>
                  <Typography
                    align="left"
                    variant="subtitle2"
                    gutterBottom
                    sx={window.innerWidth > 500 && { width: 1 / 2 }}
                  >
                    Referencia:
                  </Typography>
                  <TextField
                    fullWidth
                    align="left"
                    autoComplete="section-Entrada-Referencia"
                    size="small"
                    variant="outlined"
                    required
                    id="Referencia"
                    name="Referencia"
                    value={values.Referencia}
                    disabled={habilitar}
                    onChange={handleChange}
                  />
                </StyledDiv>
                <StyledDiv>
                  <Typography
                    align="left"
                    variant="subtitle2"
                    gutterBottom
                    sx={window.innerWidth > 500 && { width: 1 / 2 }}
                  >
                    Comentarios:
                  </Typography>
                  <TextField
                    multiline={true}
                    rows={3}
                    autoComplete="section-Entrada-Comentarios"
                    size="small"
                    fullWidth
                    variant="outlined"
                    required
                    id="Comentarios"
                    name="Comentarios"
                    value={values.Comentarios}
                    onChange={handleChange}
                    disabled={habilitar}
                  />
                </StyledDiv>
                <StyledDiv>
                  <Typography
                    align="left"
                    variant="subtitle2"
                    gutterBottom
                    sx={window.innerWidth > 500 && { width: 1 / 2 }}
                  >
                    Estatus:
                  </Typography>
                  <TextField
                    autoComplete="section-Entrada-Estatus"
                    size="small"
                    fullWidth
                    variant="outlined"
                    required
                    id="Estatus"
                    name="Estatus"
                    value={values.Estatus}
                    onChange={handleChange}
                    disabled={habilitar}
                  />
                </StyledDiv>
                <StyledDiv>
                  <Typography
                    align="left"
                    variant="subtitle2"
                    gutterBottom
                    sx={window.innerWidth > 500 && { width: 1 / 2 }}
                  >
                    Dias de credito:
                  </Typography>
                  <TextField
                    autoComplete="section-Entrada-DiasCredito"
                    size="small"
                    fullWidth
                    variant="outlined"
                    required
                    id="DiasCredito"
                    name="DiasCredito"
                    value={values.DiasCredito}
                    onChange={handleChange}
                    disabled={habilitar}
                  />
                </StyledDiv>
                <StyledDiv>
                  <Typography
                    align="left"
                    variant="subtitle2"
                    gutterBottom
                    sx={window.innerWidth > 500 && { width: 1 / 2 }}
                  >
                    Uso principal:
                  </Typography>
                  <TextField
                    autoComplete="section-Entrada-UsoPrincipal"
                    size="small"
                    fullWidth
                    variant="outlined"
                    required
                    id="UsoPrincipal"
                    name="UsoPrincipal"
                    value={values.UsoPrincipal}
                    onChange={handleChange}
                    disabled={habilitar}
                  />
                </StyledDiv>
                <StyledDiv>
                  <Typography
                    align="left"
                    variant="subtitle2"
                    gutterBottom
                    sx={window.innerWidth > 500 && { width: 1 / 2 }}
                  >
                    Método de pago
                  </Typography>
                  <TextField
                    autoComplete="section-Entrada-MetodoPago"
                    size="small"
                    fullWidth
                    variant="outlined"
                    required
                    id="MetodoPago"
                    name="MetodoPago"
                    value={values.MetodoPago}
                    onChange={handleChange}
                    disabled={habilitar}
                  />
                </StyledDiv>
              </StylePaperPrincipal>
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              {entradaMercancias.length > 0 && (
                <EntradaMercanciasDetalle
                  Entrada={entradaMercancias[iRegistro]}
                />
              )}
            </Grid>
          </Box>
        </Box>
      </ThemeProvider>
    );
  } else {
    return <Navigate to="/" />;
  }
}

EntradaMercancia.propTypes = {
  entradaMercancias: PropTypes.array,
};

export default EntradaMercancia;
