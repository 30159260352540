import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import {
  TableContainer,
  alpha,
  Table,
  Paper,
  Toolbar,
  Typography,
  Tooltip,
  TableBody,
  TableRow,
  TableCell,
  Checkbox,
  TablePagination,
  TableHead,
  TableSortLabel,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { visuallyHidden } from "@mui/utils";
import { StyledButtonEditar } from "./CtaEmpleados-css";
import { CeldasEmpleados } from "../Data/CtaEmpleados-Data";
import Cookies from "js-cookie";

//Funcion para filtrar por la busqueda
function Ordenamiento(array, comparator, Filter) {
  const rows = [];
  array.forEach((Empleado) => {
    if (
      Empleado.IdEmpleado !== undefined &&
      Empleado.IdEmpleado.toLowerCase().indexOf(Filter.toLowerCase()) !== -1
    ) {
      rows.push(Empleado);
    } else if (
      Empleado.rolEmpleado !== undefined &&
      Empleado.rolEmpleado.toLowerCase().indexOf(Filter.toLowerCase()) !== -1
    ) {
      rows.push(Empleado);
    } else if (
      Empleado.Nombre !== undefined &&
      Empleado.Nombre.toLowerCase().indexOf(Filter.toLowerCase()) !== -1
    ) {
      rows.push(Empleado);
    } else if (
      Empleado.Apellidos !== undefined &&
      Empleado.Apellidos.toLowerCase().indexOf(Filter.toLowerCase()) !== -1
    ) {
      rows.push(Empleado);
    } else if (
      Empleado.Correo !== undefined &&
      Empleado.Correo.toLowerCase().indexOf(Filter.toLowerCase()) !== -1
    ) {
      rows.push(Empleado);
    } else if (
      Empleado.Area !== undefined &&
      Empleado.Area.toLowerCase().indexOf(Filter.toLowerCase()) !== -1
    ) {
      rows.push(Empleado);
    } else if (
      Empleado.Estatus !== undefined &&
      Empleado.Estatus.toLowerCase().indexOf(Filter.toLowerCase()) !== -1
    ) {
      rows.push(Empleado);
    }
  });

  const stabilizedThis = rows.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function getComparador(order, orderBy) {
  return order === "desc"
    ? (a, b) => ComparadorDecendiente(a, b, orderBy)
    : (a, b) => -ComparadorDecendiente(a, b, orderBy);
}

function ComparadorDecendiente(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function EncabezadoDatos(props) {
  const { order, orderBy, onRequestSort } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {CeldasEmpleados.map((headCell) => (
          <TableCell
            typeof="Password"
            key={headCell.id}
            size="small"
            align={headCell.numeric}
            padding="normal"
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{ minWidth: headCell.minWidth }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function CtaEmpleadosTabla(props) {
  const navigate = useNavigate();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("Usuario");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const tipoEmpleado = Cookies.get("tipoEmpleado");
  const operador = Cookies.get("operador");
  const [editar, setEditar] = React.useState(false);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  React.useEffect(() => {
    if (operador === "Empleado" && tipoEmpleado === "Administrador") {
      setEditar(true);
    } else {
      setEditar(false);
    }
  }, [operador, tipoEmpleado]);

  const handleClick = (event, id) => {
    navigate("/Catalogo-Empleados/Empleado/" + id);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - props.rows.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      Ordenamiento(
        props.rows,
        getComparador(order, orderBy),
        props.filtro
      ).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [order, orderBy, page, rowsPerPage, props.filtro, props.rows]
  );

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%" }}>
        <TableContainer>
          <Table>
            <EncabezadoDatos
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={props.rows.length}
            />
            <TableBody>
              {visibleRows.map((row, index) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <TableRow
                    hover
                    onDoubleClick={(event) => handleClick(event, row.id)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    selected={isItemSelected}
                    sx={{ cursor: "pointer" }}
                  >
                    <TableCell
                      id={labelId}
                      key={labelId}
                      scope="row"
                      padding="normal"
                    >
                      {row.IdEmpleado}
                    </TableCell>
                    <TableCell>
                      {row.rolEmpleado === "G" ? "General" : "Administrador"}
                    </TableCell>
                    <TableCell>{row.Nombre}</TableCell>
                    <TableCell>{row.Apellidos}</TableCell>
                    <TableCell>{row.Correo}</TableCell>
                    <TableCell>{row.Area}</TableCell>
                    <TableCell>{row.Estatus}</TableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 53 * emptyRows,
                  }}
                ></TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[
            5,
            10,
            15,
            { value: props.rows.length, label: "All" },
          ]}
          component="div"
          count={props.rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}

CtaEmpleadosTabla.propTypes = {
  row: PropTypes.object,
};

export default CtaEmpleadosTabla;
