import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import Typography from "@mui/material/Typography";
import Cookies from "js-cookie";
import { StylePaperButton, StyledButton } from "./Styled-FacturasPendientes";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FacturasPendientesDialog(props) {
  const [open, setOpen] = React.useState(false);

  const usuario = Cookies.get("usuario");
  const operador = Cookies.get("operador");
  const id = Cookies.get("id");
  const access = Cookies.get("access");
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  async function handleAceptar() {
    console.log(props.rows.length);
    console.log(props.selected.length);

    const rows = [];
    props.rows.forEach((Factura) => {
      if (Factura.Revisado) {
        const Values = {
          usuario: usuario,
          id: id,
          operador: operador,
          Factura: Factura.Factura,
          Comentarios: Factura.Comentarios,
          Estatus: Factura.Estatus,
          BD: Factura.BD,
          Correo: Factura.Correo,
        };

        rows.push(Values);
      }
    });

    const Peticion = new Request("/api/facturasPendientes/patchfactura", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-access-token": access,
      },
      method: "PATCH",
      body: JSON.stringify(rows),
    });

    try {
      const response = await fetch(Peticion);
      const data = await response.json();
      if (data.status === "success") {
        props.setTipoMensaje("success");
        props.setErrorMensaje("Se proceso con exito el ó los registro(s)");
      } else {
        props.setTipoMensaje("error");
        props.setErrorMensaje("No se procesaron los registro(s)");
      }
      props.handleReload();
      setOpen(false);
    } catch (error) {
      console.error("Failed to fetch from updateSociedad", error);
    }

    props.setError(true);
    props.setOpenMessage(true);
  }

  const lista = props.selected.map((item) => (
    <Typography gutterBottom component="div" key={item} id={item}>
      {props.rows[item - 1].Codigo}
    </Typography>
  ));

  if (props.selected.length > 0) {
    return (
      <React.Fragment>
        <StylePaperButton>
          <StyledButton
            type="submit"
            variant="contained"
            color="primary"
            onClick={handleClickOpen}
          >
            Agregar
          </StyledButton>
        </StylePaperButton>
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle>
            {
              "Se cambiara el estado de las facturas seleccionadas, estas seguro?"
            }
          </DialogTitle>
          <DialogContent dividers>
            <Typography gutterBottom>
              Las siguientes facturas con los siguientes folios seran
              actulizadas:
            </Typography>
            {lista}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancelar</Button>
            <Button onClick={handleAceptar}>Aceptar</Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  } else {
    return null;
  }
}
FacturasPendientesDialog.propTypes = {};
