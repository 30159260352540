import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { Grid, Toolbar, AppBar, Tooltip } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import RefreshIcon from "@mui/icons-material/Refresh";
import {
  StyledSearch,
  StyledSearchIconWrapper,
  StyledInputBase,
  StyledButton,
  StyledButtonReload,
} from "../CtaProveddores-css";
import Cookies from "js-cookie";
import TablaSociedades from "./TablaSociedades";

function TablaSociedadesDetalles(props) {
  const Navigate = useNavigate();
  const { filtro, setFiltro, sociedades, handleForma, idProveedor } = props;
  const tipoEmpleado = Cookies.get("tipoEmpleado");
  const OnButtonClick = (event) => {
    if (event.target.id === "btnCrear") {
      Navigate(
        "/Catalogo-Proveedores/Proveedor/" + idProveedor + "/Sociedad/Nuevo"
      );
    }
  };

  return (
    <AppBar
      position="static"
      sx={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }}
    >
      <Toolbar>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs>
            <StyledSearch>
              <StyledSearchIconWrapper>
                <SearchIcon />
              </StyledSearchIconWrapper>
              <StyledInputBase
                placeholder="Search…"
                inputProps={{ "aria-label": "search" }}
                value={filtro}
                onChange={(e) => setFiltro(e.target.value)}
              />
            </StyledSearch>
          </Grid>
          <Grid>
            {tipoEmpleado === "Administrador" && (
              <StyledButton id="btnCrear" onClick={OnButtonClick}>
                Agregar
              </StyledButton>
            )}

            <Tooltip title="Recargar">
              <StyledButtonReload
                color="inherit"
                onClick={handleForma}
                size="medium"
              >
                <RefreshIcon
                  id="btnRefreshEmpleado"
                  color="inherit"
                  sx={{ display: "block" }}
                />
              </StyledButtonReload>
            </Tooltip>
          </Grid>
        </Grid>
      </Toolbar>
      {sociedades !== undefined &&
        sociedades !== "" &&
        sociedades.length > 0 && (
          <TablaSociedades
            filtro={filtro}
            rows={sociedades}
            idProveedor={idProveedor}
          />
        )}
    </AppBar>
  );
}

TablaSociedadesDetalles.propTypes = {
  sociedades: PropTypes.array,
};

export default TablaSociedadesDetalles;
