export function obtenerUsoSAT(sUso) {
  var oUsoPrincipal = [
    {
      CODIGO: "-1",
      DESCRIPCION: "",
    },
    {
      CODIGO: "G01",
      DESCRIPCION: "Adquisición de mercancias",
    },
    {
      CODIGO: "G02",
      DESCRIPCION: "Devoluciones, descuentos o bonificaciones",
    },
    {
      CODIGO: "G03",
      DESCRIPCION: "Gastos en general",
    },
    {
      CODIGO: "I01",
      DESCRIPCION: "Construcciones",
    },
    {
      CODIGO: "I02",
      DESCRIPCION: "Mobiliario y equipo de oficina por inversiones",
    },
    {
      CODIGO: "I03",
      DESCRIPCION: "Equipo de transporte",
    },
    {
      CODIGO: "I04",
      DESCRIPCION: "Equipo de computo y accesorios",
    },
    {
      CODIGO: "I05",
      DESCRIPCION: "Dados, troqueles, moldes, matrices y herramental",
    },
    {
      CODIGO: "I06",
      DESCRIPCION: "Comunicaciones telefónicas",
    },
    {
      CODIGO: "I07",
      DESCRIPCION: "Comunicaciones satelitales",
    },
    {
      CODIGO: "I08",
      DESCRIPCION: "Otra maquinaria y equipo",
    },
    {
      CODIGO: "D01",
      DESCRIPCION: "Honorarios médicos, dentales y gastos hospitalarios",
    },
    {
      CODIGO: "D02",
      DESCRIPCION: "Gastos médicos por incapacidad o discapacidad",
    },
    {
      CODIGO: "D03",
      DESCRIPCION: "Gastos funerales",
    },
    {
      CODIGO: "D04",
      DESCRIPCION: "Donativos",
    },
    {
      CODIGO: "D05",
      DESCRIPCION:
        "Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación)",
    },
    {
      CODIGO: "D06",
      DESCRIPCION: "Aportaciones voluntarias al SAR",
    },
    {
      CODIGO: "D07",
      DESCRIPCION: "Primas por seguros de gastos médicos",
    },
    {
      CODIGO: "D08",
      DESCRIPCION: "Gastos de transportación escolar obligatoria",
    },
    {
      CODIGO: "D09",
      DESCRIPCION:
        "Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones",
    },
    {
      CODIGO: "D10",
      DESCRIPCION: "Pagos por servicios educativos (colegiaturas)",
    },
    {
      CODIGO: "S01",
      DESCRIPCION: "Sin efectos fiscales",
    },
    {
      CODIGO: "CP01",
      DESCRIPCION: "Pagos",
    },
    {
      CODIGO: "CN01",
      DESCRIPCION: "Nómina",
    },
    {
      CODIGO: "P01",
      DESCRIPCION: "Por definir",
    },
    {
      CODIGO: "-9",
      DESCRIPCION: "Definir de nuevo",
    },
  ];
  let iContador = 0;
  for (iContador = 0; iContador < oUsoPrincipal.length; iContador++) {
    if (sUso === oUsoPrincipal[iContador].CODIGO) {
      return oUsoPrincipal[iContador].DESCRIPCION;
    }
  }
}

export function obtenerMetodPagoSAT(MP) {
  var oMetodoPago = [
    {
      CODIGO: "PPD",
      DESCRIPCION: "Pago en Parcialidades o Diferido",
    },
    {
      CODIGO: "PUE",
      DESCRIPCION: "Pago en Una Sola Exhibición",
    },
  ];
  let iContador = 0;
  for (iContador = 0; iContador < oMetodoPago.length; iContador++) {
    if (MP === oMetodoPago[iContador].CODIGO) {
      return oMetodoPago[iContador].DESCRIPCION;
    }
  }
}

export function obtenerFormaDePago(ID) {
  var oFormaDePago = [
    {
      CODIGO: "01",
      DESCRIPCION: "Efectivo",
    },
    {
      CODIGO: "02",
      DESCRIPCION: "Cheque nominativo",
    },
    {
      CODIGO: "03",
      DESCRIPCION: "Transferencia electrónica de fondos",
    },
    {
      CODIGO: "04",
      DESCRIPCION: "Tarjeta de crédito",
    },
    {
      CODIGO: "05",
      DESCRIPCION: "Monedero electrónic",
    },
    {
      CODIGO: "06",
      DESCRIPCION: "Dinero electrónico",
    },
    {
      CODIGO: "08",
      DESCRIPCION: "Vales de despensa",
    },
    {
      CODIGO: "12",
      DESCRIPCION: "Dación de pago",
    },
    {
      CODIGO: "13",
      DESCRIPCION: "Pago por subrogación",
    },
    {
      CODIGO: "14",
      DESCRIPCION: "Pago por consignación",
    },
    {
      CODIGO: "15",
      DESCRIPCION: "Condonación",
    },
    {
      CODIGO: "17",
      DESCRIPCION: "Compensación",
    },
    {
      CODIGO: "23",
      DESCRIPCION: "Novación",
    },
    {
      CODIGO: "24",
      DESCRIPCION: "Confusión",
    },
    {
      CODIGO: "25",
      DESCRIPCION: "Remisión de deuda",
    },
    {
      CODIGO: "26",
      DESCRIPCION: "Prescripción o caducidad",
    },
    {
      CODIGO: "27",
      DESCRIPCION: "A satisfacción del acreedor",
    },
    {
      CODIGO: "28",
      DESCRIPCION: "Tarjeta de débito",
    },
    {
      CODIGO: "29",
      DESCRIPCION: "Tarjeta de servicios",
    },
    {
      CODIGO: "30",
      DESCRIPCION: "Aplicacion de anticipos",
    },
    {
      CODIGO: "31",
      DESCRIPCION: "Intermediario pagos",
    },
    {
      CODIGO: "99",
      DESCRIPCION: "Por definir",
    },
  ];
  let iContador = 0;
  for (iContador = 0; iContador < oFormaDePago.length; iContador++) {
    if (ID === oFormaDePago[iContador].CODIGO) {
      return oFormaDePago[iContador].DESCRIPCION;
    }
  }
}

export function ObtenerRegimenFiscal(ID) {
  var oRegimenFiscal = [
    {
      CODIGO: "-1",
      DESCRIPCION: "",
    },
    {
      CODIGO: "601",
      DESCRIPCION: "General de Ley Personas Morales.",
    },
    {
      CODIGO: "603",
      DESCRIPCION: "Personas Morales con Fines no Lucrativos.",
    },
    {
      CODIGO: "605",
      DESCRIPCION: "Sueldos y Salarios e Ingresos Asimilados a Salarios.",
    },
    {
      CODIGO: "606",
      DESCRIPCION: "Arrendamiento.",
    },
    {
      CODIGO: "607",
      DESCRIPCION: "Régimen de Enajenación o Adquisición de Bienes.",
    },
    {
      CODIGO: "608",
      DESCRIPCION: "Demás ingresos.",
    },
    {
      CODIGO: "610",
      DESCRIPCION:
        "Residentes en el Extranjero sin Establecimiento Permanente en México.",
    },
    {
      CODIGO: "611",
      DESCRIPCION: "Ingresos por Dividendos (socios y accionistas).",
    },
    {
      CODIGO: "612",
      DESCRIPCION:
        "Personas Físicas con Actividades Empresariales y Profesionales.",
    },
    {
      CODIGO: "614",
      DESCRIPCION: "Ingresos por intereses.",
    },
    {
      CODIGO: "615",
      DESCRIPCION: "Otra maquinaria y equipo.",
    },
    {
      CODIGO: "616",
      DESCRIPCION: "Sin obligaciones fiscales.",
    },
    {
      CODIGO: "620",
      DESCRIPCION:
        "Sociedades Cooperativas de Producción que optan por diferir sus ingresos.",
    },
    {
      CODIGO: "621",
      DESCRIPCION: "Incorporación Fiscal.",
    },
    {
      CODIGO: "622",
      DESCRIPCION: "Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras.",
    },
    {
      CODIGO: "623",
      DESCRIPCION: "Opcional para Grupos de Sociedades.",
    },
    {
      CODIGO: "624",
      DESCRIPCION: "Coordinados.",
    },
    {
      CODIGO: "625",
      DESCRIPCION:
        "Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas.",
    },
    {
      CODIGO: "626",
      DESCRIPCION: "Régimen Simplificado de Confianza.",
    },
  ];
  let iContador = 0;
  for (iContador = 0; iContador < oRegimenFiscal.length; iContador++) {
    if (ID === oRegimenFiscal[iContador].CODIGO) {
      return oRegimenFiscal[iContador].DESCRIPCION;
    }
  }
}

export function FormatoMoneda(num) {
  return `${num.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}`;
}
