import React from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import { useNavigate, Navigate } from "react-router-dom";
import { Header, MenuLateral } from "../../Header/Header";
import {
  Box,
  Toolbar,
  Typography,
  Select,
  Grid,
  Snackbar,
  MenuItem,
  Alert,
} from "@mui/material";
import { isAuthenticatedvalid } from "../../Router/Cookies";
import { ThemeProvider } from "@mui/material/styles";
import {
  theme,
  StyledButton,
  StylePaperPrincipal,
  StyledDiv,
  StyledTheme,
  StylePaperButton,
} from "../CtaProveddores-css";
import Cookies from "js-cookie";

function TablaAgregarSociedad(props) {
  let params = useParams();

  const [values, setValues] = React.useState({
    CardCode: "",
    IdSociedad: "",
  });
  const [Generacion, setGeneracion] = React.useState(false);
  const [openMessage, setOpenMessage] = React.useState(false);
  const [habilarBoton, setHabilarBoton] = React.useState(false);
  const navigate = useNavigate();
  const [sociedades, setSociedades] = React.useState(null);
  let IsSession = isAuthenticatedvalid("access");
  const access = Cookies.get("access");

  const getSociedades = async (setAreas) => {
    const Peticion = new Request("/api/proveedores/getSociedades", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-access-token": access,
      },
      method: "GET",
    });
    try {
      const response = await fetch(Peticion);
      const data = await response.json();
      setAreas(data.data);
    } catch (error) {
      console.error(error);
    }
  };

  React.useEffect(() => {
    getSociedades(setSociedades);

    if (props.proveedores !== undefined && props.proveedores.length > 0) {
      setValues({
        CardCode: props.proveedores[params.id - 1].CodigoSAP,
      });
    }
  }, []);

  async function handleSubmit(e) {
    e.preventDefault();

    const Peticion = new Request("/api/proveedores/addSociedad", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-access-token": access,
      },
      method: "POST",
      body: JSON.stringify(values),
    });

    try {
      const response = await fetch(Peticion);
      const data = await response.json();
      if (data.status === "success") {
        setHabilarBoton(true);
        setGeneracion(true);
        setOpenMessage(true);
        //setError(false);
      } else {
        // setError(true);
        setOpenMessage(true);
        //setErrorMensaje(data.message);
      }
    } catch (error) {
      console.error("Failed to fetch from updateSociedad", error);
    }
  }

  function handleChange(evt) {
    const { target } = evt;
    const { name, value } = target;
    const newValues = {
      ...values,
      [name]: value,
    };
    setValues(newValues);
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setGeneracion(false);
    setOpenMessage(false);
    navigate("/Catalogo-Proveedores");
  };

  if (IsSession) {
    return (
      <ThemeProvider theme={theme}>
        <Box sx={{ display: "flex" }}>
          <Header
            IsSession={IsSession}
            open={props.open}
            setOpen={props.setOpen}
            operador={Cookies.get("operador")}
            usuario={Cookies.get("usuario")}
          />
          <MenuLateral
            IsSession={IsSession}
            open={props.open}
            setOpen={props.setOpen}
          />
          <Box
            component="main"
            sx={{
              flexGrow: 10,
              height: "100vh",
              overflow: "auto",
            }}
          >
            <Toolbar />
            <Typography
              sx={{ my: 3, mx: 2 }}
              align="center"
              variant="h5"
              component="h5"
            >
              Sociedades
            </Typography>

            <form onSubmit={handleSubmit}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <StylePaperPrincipal variant="outlined">
                  <StyledDiv>
                    <Typography
                      align="left"
                      variant="subtitle2"
                      gutterBottom
                      sx={window.innerWidth > 500 && { width: 1 / 2 }}
                    >
                      *Sociedad:
                    </Typography>
                    <Select
                      autoComplete="section-Empleado-IdSociedad"
                      size="small"
                      fullWidth
                      align="left"
                      variant="outlined"
                      required
                      id="IdSociedad"
                      name="IdSociedad"
                      value={values.IdSociedad}
                      onChange={handleChange}
                    >
                      {sociedades !== null &&
                        sociedades.map((area) => {
                          return (
                            <MenuItem
                              key={area.IdSociedad}
                              id={area.IdSociedad}
                              value={area.IdSociedad}
                            >
                              {area.NomSociedad}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </StyledDiv>
                </StylePaperPrincipal>
              </Grid>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <StylePaperButton variant="elevation" elevation={0}>
                  <StyledButton
                    type="submit"
                    variant="contained"
                    color="primary"
                    className={StyledTheme.submit}
                    disabled={habilarBoton}
                  >
                    Agregar
                  </StyledButton>
                </StylePaperButton>
              </Grid>
            </form>
          </Box>
        </Box>
        {Generacion === true && (
          <Snackbar
            open={openMessage}
            autoHideDuration={3000}
            onClose={handleClose}
          >
            <Alert
              onClose={handleClose}
              severity="success"
              variant="filled"
              sx={{ width: "100%" }}
            >
              Se creo con exito el empleado!
            </Alert>
          </Snackbar>
        )}
      </ThemeProvider>
    );
  } else {
    return <Navigate to="/" />;
  }
}

TablaAgregarSociedad.propTypes = {
  sociedades: PropTypes.array,
};

export default TablaAgregarSociedad;
