import Cookies from "js-cookie";
import { Navigate } from "react-router-dom";

// Function to get the access token from cookies
export const getAccessToken = (cookie) => {
  return Cookies.get(cookie);
};

// Function to check if the user is authenticated
export const isAuthenticatedvalid = (cookie) => {
  return !!getAccessToken(cookie);
};

// Function to get the access token from cookies
export const UpdateAccessToken = () => {
  const inFifteenMinutes = new Date(new Date().getTime() + 30 * 60 * 1000);
  if (isAuthenticatedvalid("access")) {
    Cookies.set("access", Cookies.get("access"), { expires: inFifteenMinutes });
    Cookies.set("usuario", Cookies.get("usuario"), {
      expires: inFifteenMinutes,
    });
    Cookies.set("tipoEmpleado", Cookies.get("tipoEmpleado"), {
      expires: inFifteenMinutes,
    });
    Cookies.set("operador", Cookies.get("operador"), {
      expires: inFifteenMinutes,
    });
    return true;
  } else {
    Cookies.remove("usuario");
    Cookies.remove("tipoEmpleado");
    Cookies.remove("operador");
    return false;
  }
};

export function AuthRoute({ children }) {
  const isAuthenticated = isAuthenticatedvalid("access");
  return isAuthenticated ? children : <Navigate to="/Home" children />;
}

export function PrivateRoute({ children }) {
  const isAuthenticated = isAuthenticatedvalid("access");
  return isAuthenticated ? children : <Navigate to="/" replace />;
}
