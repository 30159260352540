import React from "react";
import Cookies from "js-cookie";
import { ThemeProvider } from "@mui/material";
import { theme } from "./AvisoPrivacidad-css";
import { Box, Toolbar, Paper, Typography } from "@mui/material";
import { Header } from "../Header/Header";

function AvisoPrivacidad(props) {
  const operador = React.useState(Cookies.get("operador"));
  const usuario = Cookies.get("usuario");

  if (operador === "Proveedor") {
    return <div>AvisoPrivacidad {operador}</div>;
  } else {
    return (
      <ThemeProvider theme={theme}>
        <Box sx={{ display: "flex" }}>
          <Header
            IsSession={false}
            open={false}
            setOpen={props.setOpen}
            operador={operador}
            usuario={usuario}
          />
          <Box
            sx={{
              flexGrow: 10,
              height: "100vh",
              overflow: "auto",
            }}
          >
            <Toolbar />

            <Typography
              color="text.secondary"
              align="center"
              variant="h4"
              component="h4"
              margin={2}
            >
              Aviso de privacidad
            </Typography>
            <Paper sx={{ maxWidth: "90%", margin: "auto", overflow: "hidden" }}>
              <div>
                <p>
                  [Nombre de la Empresa] con domicilio en [Direcci&oacute;n
                  completa], es responsable del tratamiento de sus datos
                  personales.Para la adecuada &nbsp;prestaci&oacute;n de
                  nuestros servicios y la gesti&oacute;n de la relaci&oacute;n
                  comercial con nuestros proveedores, recabamos las siguientes
                  categor&iacute;as de &nbsp;datos personales:
                </p>
                <p>
                  <br />
                </p>
                <p>
                  &nbsp; &nbsp; &nbsp;* &nbsp;Informaci&oacute;n de contacto:
                  nombre, direcci&oacute;n, correo electr&oacute;nico,
                  tel&eacute;fono.
                </p>
                <p>
                  &nbsp; &nbsp; &nbsp;* &nbsp;Informaci&oacute;n de
                  identificaci&oacute;n: RFC, CURP, identificaci&oacute;n
                  oficial.
                </p>
                <p>
                  &nbsp; &nbsp; &nbsp;* &nbsp;Informaci&oacute;n bancaria y
                  financiera: datos de cuenta bancaria, historial de pagos.
                </p>
                <p>
                  &nbsp; &nbsp; &nbsp;* &nbsp;Informaci&oacute;n comercial:
                  productos o servicios ofrecidos, condiciones de venta,
                  referencias comerciales.
                </p>
                <p>
                  <br />
                </p>
                <p>
                  br/ datos personales que recabamos ser&aacute;n utilizados
                  para las siguientes finalidades:
                </p>
                <p>
                  <br />
                </p>
                <p>
                  &nbsp; &nbsp; &nbsp;* &nbsp;Gestionar la relaci&oacute;n
                  comercial con nuestros proveedores.
                </p>
                <p>
                  &nbsp; &nbsp; &nbsp;* &nbsp;Realizar pagos y transacciones
                  financieras.
                </p>
                <p>
                  &nbsp; &nbsp; &nbsp;* &nbsp;Cumplir con obligaciones legales y
                  regulatorias.
                </p>
                <p>
                  &nbsp; &nbsp; &nbsp;* &nbsp;Evaluar y seleccionar proveedores.
                </p>
                <p>
                  &nbsp; &nbsp; &nbsp;* &nbsp;Mantener comunicaci&oacute;n
                  constante y efectiva para el desarrollo de proyectos y/o
                  solicitudes de productos o servicios.
                </p>
                <p>
                  <br />
                </p>
                <p>
                  [Nombre de la Empresa] podr&aacute; transferir sus datos
                  personales a terceros nacionales o extranjeros,
                  &uacute;nicamente para los fines necesarios relacionados con
                  la relaci&oacute;n comercial, tales como:
                </p>
                <p>
                  <br />
                </p>
                <p>
                  &nbsp; &nbsp; &nbsp; &nbsp;* &nbsp;Instituciones bancarias
                  para la gesti&oacute;n de pagos.
                </p>
                <p>
                  &nbsp; &nbsp; &nbsp; &nbsp;* &nbsp;Autoridades competentes en
                  los casos legalmente previstos.
                </p>
                <p>
                  &nbsp; &nbsp; &nbsp; &nbsp;* &nbsp;Empresas filiales,
                  subsidiarias y socios comerciales, siempre y cuando sea
                  necesario para la gesti&oacute;n de la relaci&oacute;n
                  comercial.
                </p>
                <p>
                  <br />
                </p>
                <p>
                  Implementamos medidas de seguridad administrativas,
                  t&eacute;cnicas y f&iacute;sicas para proteger sus datos
                  personales contra da&ntilde;o, p&eacute;rdida,
                  alteraci&oacute;n, destrucci&oacute;n o uso, acceso o
                  tratamiento no autorizado.
                </p>
                <p>
                  <br />
                </p>
                <p>
                  Usted tiene derecho a acceder, rectificar, cancelar u oponerse
                  al tratamiento de sus datos personales (Derechos ARCO). Para
                  ejercer estos derechos, puede contactar a nuestro Departamento
                  de Protecci&oacute;n de Datos Personales en [correo
                  electr&oacute;nico y/o tel&eacute;fono] o visitar nuestra
                  p&aacute;gina web [URL de la empresa].
                </p>
                <p>
                  <br />
                </p>
                <p>
                  Nos reservamos el derecho de efectuar en cualquier momento
                  modificaciones o actualizaciones al presente aviso de
                  privacidad, para la atenci&oacute;n de novedades legislativas,
                  pol&iacute;ticas internas o nuevos requerimientos para la
                  prestaci&oacute;n u ofrecimiento de nuestros servicios. Estas
                  modificaciones estar&aacute;n disponibles a trav&eacute;s de
                  los siguientes medios: [mencionar c&oacute;mo se
                  notificar&aacute;n las modificaciones, por ejemplo, sitio web,
                  correo electr&oacute;nico].
                </p>
                <p>
                  <br />
                </p>
                <p>
                  Al proporcionar sus datos personales, usted consiente que
                  estos sean tratados conforme a lo se&ntilde;alado en el
                  presente aviso de privacidad.
                </p>
                <p>
                  <br />
                </p>
                <p>Contacto</p>
                <p>
                  <br />
                </p>
                <p>
                  Si tiene alguna duda o comentario respecto al presente aviso
                  de privacidad, puede contactarnos en:
                </p>
                <p>
                  <br />
                </p>
                <p>[Nombre de la Empresa]</p>
                <p>[Direcci&oacute;n]</p>
                <p>[Correo Electr&oacute;nico]</p>
                <p>[Tel&eacute;fono]</p>
              </div>
            </Paper>
          </Box>
        </Box>
      </ThemeProvider>
    );
  }
}

export default AvisoPrivacidad;
