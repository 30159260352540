import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import {
  TableContainer,
  Table,
  Paper,
  Tooltip,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  TableHead,
  TableSortLabel,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import { CeldasEntradas } from "../Data/EntradasMercancia-Data";
import {
  obtenerUsoSAT,
  obtenerMetodPagoSAT,
  obtenerFormaDePago,
} from "../Data/DatosSAT";

//Funcion para filtrar por la busqueda
function Ordenamiento(array, comparator, Filter) {
  const rows = [];
  array.forEach((Entrada) => {
    if (
      Entrada.Numero !== undefined &&
      Entrada.Numero !== "" &&
      Entrada.Numero !== null &&
      Entrada.Numero.toString().toLowerCase().indexOf(Filter.toLowerCase()) !==
        -1
    ) {
      rows.push(Entrada);
    } else if (
      Entrada.Sociedad !== undefined &&
      Entrada.Sociedad !== "" &&
      Entrada.Sociedad !== null &&
      Entrada.Sociedad.toLowerCase().indexOf(Filter.toLowerCase()) !== -1
    ) {
      rows.push(Entrada);
    } else if (
      Entrada.NomProveedor !== undefined &&
      Entrada.NomProveedor !== "" &&
      Entrada.NomProveedor !== null &&
      Entrada.NomProveedor.toLowerCase().indexOf(Filter.toLowerCase()) !== -1
    ) {
      rows.push(Entrada);
    } else if (
      Entrada.Entrada !== undefined &&
      Entrada.Entrada !== "" &&
      Entrada.Entrada !== null &&
      Entrada.Entrada.toString().toLowerCase().indexOf(Filter.toLowerCase()) !==
        -1
    ) {
      rows.push(Entrada);
    } else if (
      Entrada.Pedido !== undefined &&
      Entrada.Pedido !== "" &&
      Entrada.Pedido !== null &&
      Entrada.Pedido.toString().toLowerCase().indexOf(Filter.toLowerCase()) !==
        -1
    ) {
      rows.push(Entrada);
    } else if (
      Entrada.Estatus !== undefined &&
      Entrada.Estatus !== "" &&
      Entrada.Estatus !== null &&
      Entrada.Estatus.toString().toLowerCase().indexOf(Filter.toLowerCase()) !==
        -1
    ) {
      rows.push(Entrada);
    } else if (
      Entrada.Fecha !== undefined &&
      Entrada.Fecha !== "" &&
      Entrada.Fecha !== null &&
      Entrada.Fecha.toString().toLowerCase().indexOf(Filter.toLowerCase()) !==
        -1
    ) {
      rows.push(Entrada);
    } else if (
      Entrada.FechaVencimiento !== undefined &&
      Entrada.FechaVencimiento !== "" &&
      Entrada.FechaVencimiento !== null &&
      Entrada.FechaVencimiento.toString()
        .toLowerCase()
        .indexOf(Filter.toLowerCase()) !== -1
    ) {
      rows.push(Entrada);
    } else if (
      Entrada.DiasCredito !== undefined &&
      Entrada.DiasCredito !== "" &&
      Entrada.DiasCredito !== null &&
      Entrada.DiasCredito.toString()
        .toLowerCase()
        .indexOf(Filter.toLowerCase()) !== -1
    ) {
      rows.push(Entrada);
    } else if (
      Entrada.UsoPrincipal !== undefined &&
      Entrada.UsoPrincipal !== "" &&
      Entrada.UsoPrincipal !== null &&
      Entrada.UsoPrincipal.toString()
        .toLowerCase()
        .indexOf(Filter.toLowerCase()) !== -1
    ) {
      rows.push(Entrada);
    } else if (
      Entrada.MetodoPago !== undefined &&
      Entrada.MetodoPago !== "" &&
      Entrada.MetodoPago !== null &&
      Entrada.MetodoPago.toString()
        .toLowerCase()
        .indexOf(Filter.toLowerCase()) !== -1
    ) {
      rows.push(Entrada);
    } else if (
      Entrada.UsoPrincipal !== undefined &&
      Entrada.UsoPrincipal !== "" &&
      Entrada.UsoPrincipal !== null &&
      Entrada.UsoPrincipal.toString()
        .toLowerCase()
        .indexOf(Filter.toLowerCase()) !== -1
    ) {
      rows.push(Entrada);
    } else if (
      Entrada.Total !== undefined &&
      Entrada.Total !== "" &&
      Entrada.Total !== null &&
      Entrada.Total.toString().toLowerCase().indexOf(Filter.toLowerCase()) !==
        -1
    ) {
      rows.push(Entrada);
    }
  });

  const stabilizedThis = rows.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function getComparador(order, orderBy) {
  return order === "desc"
    ? (a, b) => ComparadorDecendiente(a, b, orderBy)
    : (a, b) => -ComparadorDecendiente(a, b, orderBy);
}

function ComparadorDecendiente(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function EncabezadoDatos(props) {
  const { order, orderBy, onRequestSort } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {CeldasEntradas.map((headCell) => (
          <TableCell
            typeof="Password"
            key={headCell.id}
            size="small"
            align={headCell.numeric ? "right" : "center"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{ minWidth: headCell.minWidth }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function EntradaMercanciasTabla(props) {
  const navigate = useNavigate();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("#");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [usoPrincipal, setUsoPrincipal] = React.useState("");
  const [metodoPago, setMetodoPago] = React.useState("");
  const [formaPago, setFormaPago] = React.useState("");

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleClick = (event, id) => {
    navigate("/Entradas-Mercancia/EntradaMercancia/" + id);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - props.rows.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      Ordenamiento(
        props.rows,
        getComparador(order, orderBy),
        props.filtro
      ).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [order, orderBy, page, rowsPerPage, props.filtro, props.rows]
  );

  const handleChangeUso = (event, id) => {
    setUsoPrincipal(obtenerUsoSAT(id));
  };

  const handleChangeMetoPago = (event, id) => {
    setMetodoPago(obtenerMetodPagoSAT(id));
  };

  const handleChangeFormaPago = (event, id) => {
    setFormaPago(obtenerFormaDePago(id));
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%" }}>
        <TableContainer>
          <Table>
            <EncabezadoDatos
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={props.rows.length}
            />
            <TableBody>
              {visibleRows.map((row, index) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <TableRow
                    hover
                    onDoubleClick={(event) => handleClick(event, row.id)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    sx={{ cursor: "pointer" }}
                  >
                    <TableCell
                      component="th"
                      id={labelId}
                      key={labelId}
                      scope="row"
                      padding="normal"
                    >
                      {row.Numero}
                    </TableCell>
                    <TableCell padding="checkbox" align="center">
                      {row.Sociedad}
                    </TableCell>
                    <TableCell padding="checkbox" align="center">
                      {row.NomProveedor}
                    </TableCell>
                    <TableCell padding="checkbox" align="center">
                      {row.Entrada}
                    </TableCell>
                    <TableCell padding="checkbox" align="center">
                      {row.Pedido}
                    </TableCell>
                    <TableCell padding="checkbox" align="center">
                      {row.Estatus}
                    </TableCell>
                    <TableCell padding="checkbox" align="center">
                      {row.Fecha}
                    </TableCell>
                    <TableCell padding="checkbox" align="center">
                      {row.FechaVencimiento}
                    </TableCell>
                    <TableCell padding="checkbox" align="center">
                      {row.DiasCredito}
                    </TableCell>
                    <TableCell
                      padding="checkbox"
                      align="center"
                      onMouseOver={(event) =>
                        handleChangeUso(event, row.UsoPrincipal)
                      }
                    >
                      <Tooltip title={usoPrincipal}>{row.UsoPrincipal}</Tooltip>
                    </TableCell>

                    <TableCell
                      padding="checkbox"
                      align="center"
                      onMouseOver={(event) =>
                        handleChangeMetoPago(event, row.MetodoPago)
                      }
                    >
                      <Tooltip title={metodoPago}>{row.MetodoPago}</Tooltip>
                    </TableCell>
                    <TableCell
                      padding="checkbox"
                      align="center"
                      onMouseOver={(event) =>
                        handleChangeFormaPago(event, row.FormaPago)
                      }
                    >
                      <Tooltip title={formaPago}>{row.FormaPago}</Tooltip>
                    </TableCell>
                    <TableCell padding="checkbox" align="right">
                      {row.Total + " " + row.Moneda}
                    </TableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 53 * emptyRows,
                  }}
                ></TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[
            5,
            10,
            15,
            { value: props.rows.length, label: "All" },
          ]}
          component="div"
          count={props.rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}

EntradaMercanciasTabla.propTypes = {
  row: PropTypes.object,
};

export default EntradaMercanciasTabla;
