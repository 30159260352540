import React from "react";
import PropTypes from "prop-types";
import { ThemeProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import { Grid, Toolbar } from "@mui/material";
import { theme, StyledPaper, StyledButton, Styleddiv } from "./Principal-css";
import { Header, MenuLateral } from "../Header/Header";
import Proveedor from "../../Assets/Proveedor.png";
import Empleado from "../../Assets/Empleado.png";
import Cookies from "js-cookie";
import { isAuthenticatedvalid } from "../Router/Cookies";

function Principal(props) {
  let navigate = useNavigate();
  let IsSession = isAuthenticatedvalid("access");

  const OnButtonClick = (event) => {
    if (event.target.id === "btnProveedor") {
      Cookies.set("operador", "Proveedor");
    } else {
      Cookies.set("operador", "Empleado");
    }

    navigate("/Login");
  };

  React.useEffect(() => {
    if (IsSession === true) {
      navigate("/Home");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Box>
        <Header IsSession={false} open={props.open} setOpen={props.setOpen} />
        <MenuLateral
          IsSession={false}
          open={props.open}
          setOpen={props.setOpen}
        />
        <Box>
          <Toolbar>
            <Grid container justifyContent="center">
              <StyledPaper elevation={0}>
                <Styleddiv>
                  <img src={Proveedor} alt="Proveedor" width="75%" />
                </Styleddiv>
                <Styleddiv>
                  <StyledButton
                    id="btnProveedor"
                    variant="contained"
                    onClick={OnButtonClick}
                  >
                    Ingresar
                  </StyledButton>
                </Styleddiv>
              </StyledPaper>
              <StyledPaper elevation={0}>
                <Styleddiv>
                  <img src={Empleado} alt="Empleado" width="75%" />
                </Styleddiv>
                <Styleddiv>
                  <StyledButton
                    id="btnEmpleado"
                    variant="contained"
                    onClick={OnButtonClick}
                  >
                    Ingresar
                  </StyledButton>
                </Styleddiv>
              </StyledPaper>
            </Grid>
          </Toolbar>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

Principal.propTypes = {
  open: PropTypes.bool,
};

export default Principal;
