import React from "react";
import PropTypes from "prop-types";
import { Grid, Toolbar, Paper, Typography, AppBar } from "@mui/material";
import PedidoTablaDetalle from "./PedidoTablaDetalle";

function PedidosDetalle(props) {
  return (
    <Paper sx={{ maxWidth: "90%", margin: "auto", overflow: "hidden" }}>
      <AppBar
        position="static"
        sx={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }}
      >
        <Toolbar>
          <Grid container spacing={2} alignItems="center">
            <Typography
              sx={{ flex: "1 1 100%" }}
              variant="h6"
              id="tableTitle"
              component="div"
            >
              Detalle del pedidos
            </Typography>
          </Grid>
        </Toolbar>
        {
          <PedidoTablaDetalle
            rows={props.Pedido.DetallePedido}
            EncabezadoPedido={props.Pedido}
          />
        }
      </AppBar>
    </Paper>
  );
}

PedidosDetalle.propTypes = {
  Pedido: PropTypes.object,
};

export default PedidosDetalle;
