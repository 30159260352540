import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import {
  TableContainer,
  Table,
  Paper,
  TableBody,
  TableRow,
  TableCell,
  Checkbox,
  TablePagination,
  TableHead,
  TextField,
  Select,
  MenuItem,
  Tooltip,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import { StyledButtonEditar } from "./Styled-PagosPendientes";
import { CeldasPagosPendientes } from "../Data/PagosPendientes-Data";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { obtenerFormaDePago } from "../Data/DatosSAT";

//Funcion para filtrar por la busqueda
function Ordenamiento(array, comparator, Filter) {
  const rows = [];
  array.forEach((Pago) => {
    if (
      Pago.Numero !== undefined &&
      Pago.Numero !== "" &&
      Pago.Numero !== null &&
      Pago.Numero.toString().toLowerCase().indexOf(Filter.toLowerCase()) !== -1
    ) {
      rows.push(Pago);
    } else if (
      Pago.Sociedad !== undefined &&
      Pago.Sociedad !== "" &&
      Pago.Sociedad !== null &&
      Pago.Sociedad.toLowerCase().indexOf(Filter.toLowerCase()) !== -1
    ) {
      rows.push(Pago);
    } else if (
      Pago.NomProveedor !== undefined &&
      Pago.NomProveedor !== "" &&
      Pago.NomProveedor !== null &&
      Pago.NomProveedor.toLowerCase().indexOf(Filter.toLowerCase()) !== -1
    ) {
      rows.push(Pago);
    } else if (
      Pago.UUID !== undefined &&
      Pago.UUID !== "" &&
      Pago.UUID !== null &&
      Pago.UUID.toString().toLowerCase().indexOf(Filter.toLowerCase()) !== -1
    ) {
      rows.push(Pago);
    } else if (
      Pago.PagoSAP !== undefined &&
      Pago.PagoSAP !== "" &&
      Pago.PagoSAP !== null &&
      Pago.PagoSAP.toString().toLowerCase().indexOf(Filter.toLowerCase()) !== -1
    ) {
      rows.push(Pago);
    } else if (
      Pago.UUID !== undefined &&
      Pago.UUID !== "" &&
      Pago.UUID !== null &&
      Pago.UUID.toString().toLowerCase().indexOf(Filter.toLowerCase()) !== -1
    ) {
      rows.push(Pago);
    } else if (
      Pago.Fecha !== undefined &&
      Pago.Fecha !== "" &&
      Pago.Fecha !== null &&
      Pago.Fecha.toString().toLowerCase().indexOf(Filter.toLowerCase()) !== -1
    ) {
      rows.push(Pago);
    } else if (
      Pago.FechaPago !== undefined &&
      Pago.FechaPago !== "" &&
      Pago.FechaPago !== null &&
      Pago.FechaPago.toString().toLowerCase().indexOf(Filter.toLowerCase()) !==
        -1
    ) {
      rows.push(Pago);
    } else if (
      Pago.TotalPago !== undefined &&
      Pago.TotalPago !== "" &&
      Pago.TotalPago !== null &&
      Pago.TotalPago.toString().toLowerCase().indexOf(Filter.toLowerCase()) !==
        -1
    ) {
      rows.push(Pago);
    } else if (
      Pago.FechaComplemento !== undefined &&
      Pago.FechaComplemento !== "" &&
      Pago.FechaComplemento !== null &&
      Pago.FechaComplemento.toString()
        .toLowerCase()
        .indexOf(Filter.toLowerCase()) !== -1
    ) {
      rows.push(Pago);
    } else if (
      Pago.EstatusComplemento !== undefined &&
      Pago.EstatusComplemento !== "" &&
      Pago.EstatusComplemento !== null &&
      Pago.EstatusComplemento.toString()
        .toLowerCase()
        .indexOf(Filter.toLowerCase()) !== -1
    ) {
      rows.push(Pago);
    } else if (
      Pago.MetodoPago !== undefined &&
      Pago.MetodoPago !== "" &&
      Pago.MetodoPago !== null &&
      Pago.MetodoPago.toString().toLowerCase().indexOf(Filter.toLowerCase()) !==
        -1
    ) {
      rows.push(Pago);
    }
  });

  const stabilizedThis = rows.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function getComparador(order, orderBy) {
  return order === "desc"
    ? (a, b) => ComparadorDecendiente(a, b, orderBy)
    : (a, b) => -ComparadorDecendiente(a, b, orderBy);
}

function ComparadorDecendiente(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function EncabezadoDatos(props) {
  const { order, orderBy } = props;

  return (
    <TableHead>
      <TableRow>
        {CeldasPagosPendientes.map((headCell) => (
          <TableCell
            typeof="Password"
            key={headCell.id}
            size="small"
            align={headCell.numeric ? "right" : "center"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{ minWidth: headCell.minWidth }}
          >
            {headCell.label}
            {orderBy === headCell.id ? (
              <Box component="span" sx={visuallyHidden}>
                {order === "desc" ? "sorted descending" : "sorted ascending"}
              </Box>
            ) : null}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function PagosPendientesTabla(props) {
  let order = "asc";
  let orderBy = "#";
  const { selected, setSelected } = props;
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [formaPago, setFormaPago] = React.useState("");

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    const NewRows = props.rows.map((row) => {
      if (row.id === id) {
        return {
          ...row,
          Revisado: !row.Revisado,
        };
      } else {
        return row;
      }
    });
    props.setRows(NewRows);
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - props.rows.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      Ordenamiento(
        props.rows,
        getComparador(order, orderBy),
        props.filtro
      ).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [order, orderBy, page, rowsPerPage, props.filtro, props.rows]
  );

  const b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  };

  const createFile = (event, b64Data) => {
    let fileURL;
    switch (event.target.id) {
      case "PDF":
        const blobpdf = b64toBlob(b64Data, "application/pdf");
        fileURL = URL.createObjectURL(blobpdf);
        break;
      case "XML":
        const blobxml = b64toBlob(b64Data, "text/xml");
        fileURL = URL.createObjectURL(blobxml);
        break;
      case "Recepcion":
        const blobrecepcion = b64toBlob(
          b64Data,
          "image/gif, image/jpeg, image/png"
        );
        fileURL = URL.createObjectURL(blobrecepcion);
        break;
      default:
      // code block
    }

    window.open(fileURL);
  };

  function handleChange(evt, id) {
    const { target } = evt;
    const { name, value } = target;
    const NewRows = props.rows.map((row) => {
      if (row.id === id) {
        return {
          ...row,
          [name]: value,
        };
      } else {
        return row;
      }
    });
    props.setRows(NewRows);
  }

  const handleChangeFormaPago = (event, id) => {
    setFormaPago(obtenerFormaDePago(id));
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%" }}>
        <TableContainer>
          <Table>
            <EncabezadoDatos
              order={order}
              orderBy={orderBy}
              rowCount={props.rows.length}
            />
            <TableBody>
              {visibleRows.map((row, index) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    selected={isItemSelected}
                    sx={{ cursor: "pointer" }}
                  >
                    <TableCell
                      component="th"
                      id={labelId}
                      key={labelId}
                      scope="row"
                      padding="normal"
                    >
                      {row.Numero}
                    </TableCell>
                    <TableCell padding="checkbox" align="center">
                      {row.Sociedad}
                    </TableCell>
                    <TableCell padding="checkbox" align="center">
                      {row.NomProveedor}
                    </TableCell>
                    <TableCell padding="checkbox" align="center">
                      {row.UUID}
                    </TableCell>
                    <TableCell padding="checkbox" align="center">
                      {row.PagoSAP}
                    </TableCell>
                    <TableCell padding="checkbox" align="center">
                      {row.Fecha}
                    </TableCell>
                    <TableCell padding="checkbox" align="center">
                      {row.FechaPago}
                    </TableCell>
                    <TableCell
                      padding="checkbox"
                      align="center"
                      onMouseOver={(event) =>
                        handleChangeFormaPago(event, row.MetodoPago)
                      }
                    >
                      <Tooltip title={formaPago}>{row.MetodoPago}</Tooltip>
                    </TableCell>
                    <TableCell padding="checkbox" align="center">
                      {row.TotalPago + " " + row.Moneda}
                    </TableCell>
                    <TableCell padding="checkbox" align="center">
                      {row.FechaComplemento}
                    </TableCell>

                    <TableCell padding="checkbox" align="center">
                      {row.Complemento !== "" && (
                        <StyledButtonEditar
                          size="medium"
                          component="label"
                          role={undefined}
                          tabIndex={-1}
                          endIcon={<PictureAsPdfIcon />}
                          id="Complemento"
                          name="Complemento"
                          onClick={(e) => createFile(e, row.Complemento)}
                        >
                          Visualizar
                        </StyledButtonEditar>
                      )}
                    </TableCell>
                    <TableCell padding="checkbox" align="center">
                      {row.PDF !== "" && (
                        <StyledButtonEditar
                          size="medium"
                          component="label"
                          role={undefined}
                          tabIndex={-1}
                          endIcon={<PictureAsPdfIcon />}
                          id="PDF"
                          name="PDF"
                          onClick={(e) => createFile(e, row.PDF)}
                        >
                          Visualizar
                        </StyledButtonEditar>
                      )}
                    </TableCell>
                    <TableCell padding="checkbox" align="center">
                      {row.XML !== "" && (
                        <StyledButtonEditar
                          size="medium"
                          component="label"
                          role={undefined}
                          tabIndex={-1}
                          endIcon={<PictureAsPdfIcon />}
                          id="XML"
                          name="XML"
                          onClick={(e) => createFile(e, row.XML)}
                        >
                          Visualizar
                        </StyledButtonEditar>
                      )}
                    </TableCell>

                    <TableCell padding="checkbox" align="center">
                      <Select
                        autoComplete="section-PagosPendientes-EstatusComplemento"
                        size="small"
                        fullWidth
                        align="left"
                        variant="outlined"
                        required
                        id="EstatusComplemento"
                        name="EstatusComplemento"
                        value={row.EstatusComplemento}
                        disabled={row.EstatusBD === "A" ? true : false}
                        onChange={(event) => handleChange(event, row.id)}
                      >
                        <MenuItem id="area-A" value="P">
                          Pendiente
                        </MenuItem>
                        <MenuItem id="area-I" value="A">
                          Aceptada
                        </MenuItem>
                        <MenuItem id="area-I" value="R">
                          Rechazada
                        </MenuItem>
                        <MenuItem id="area-I" value="V">
                          Validación
                        </MenuItem>
                      </Select>
                    </TableCell>
                    <TableCell padding="checkbox" align="center">
                      <Checkbox
                        id="Revisado"
                        name="Revisado"
                        onClick={(event) => handleClick(event, row.id)}
                        color="primary"
                        checked={row.Revisado}
                        inputProps={{
                          "aria-labelledby": labelId,
                        }}
                        disabled={row.EstatusBD === "A" ? true : false}
                      />
                    </TableCell>
                    <TableCell padding="checkbox" align="center">
                      <TextField
                        fullWidth
                        align="left"
                        autoComplete="section-PagosPendientes-Comentarios"
                        size="small"
                        variant="outlined"
                        required
                        id="Comentarios"
                        name="Comentarios"
                        autoFocus
                        value={row.Comentarios}
                        multiline={true}
                        rows={3}
                        disabled={row.EstatusBD === "A" ? true : false}
                        onChange={(event) => handleChange(event, row.id)}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 53 * emptyRows,
                  }}
                ></TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[
            5,
            10,
            15,
            { value: props.rows.length, label: "All" },
          ]}
          component="div"
          count={props.rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}

PagosPendientesTabla.propTypes = {
  row: PropTypes.object,
};

export default PagosPendientesTabla;
