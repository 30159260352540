import React from "react";
import PropTypes from "prop-types";
import { Navigate } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import {
  Box,
  Grid,
  Toolbar,
  Paper,
  Typography,
  AppBar,
  Tooltip,
  Snackbar,
  Alert,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import RefreshIcon from "@mui/icons-material/Refresh";
import {
  theme,
  StyledSearch,
  StyledSearchIconWrapper,
  StyledInputBase,
  StyledButtonReload,
  StylePaperButtonReload,
} from "./Styled-FacturasPendientes";
import Cookies from "js-cookie";
import { Header, MenuLateral } from "../Header/Header";
import { addFacturaPendiente } from "../Data/FacturasPendientes-Data";
import { UpdateAccessToken } from "../Router/Cookies";
import FacturasPendientesTabla from "./FacturasPendientesTabla";
import FacturasPendientesDialog from "./FacturasPendientesDialog";
import CircularProgress from "@mui/material/CircularProgress";
import { FormatoMoneda } from "../Data/DatosSAT";

function FacturasPendientes(props) {
  const [filtro, setFiltro] = React.useState("");
  const [selected, setSelected] = React.useState([]);
  const { facturasPendientes, setFacturasPendientes } = props;
  const usuario = Cookies.get("usuario");
  const operador = Cookies.get("operador");
  const access = Cookies.get("access");
  const id = Cookies.get("id");
  const [error, setError] = React.useState(false);
  const [errorMensaje, setErrorMensaje] = React.useState("");
  const [openMessage, setOpenMessage] = React.useState(false);
  const [tipoMensaje, setTipoMensaje] = React.useState("");
  const [cargar, setCargar] = React.useState(true);
  let IsSession = !!Cookies.get("access");

  const fetchData = async (setFacturasPendientes) => {
    setFacturasPendientes([]);
    setSelected([]);
    setCargar(true);
    const Values = {
      usuario: id,
      operador: operador,
    };
    const Peticion = new Request("api/facturasPendientes/getFacturas", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-access-token": access,
      },
      method: "POST",
      body: JSON.stringify(Values),
    });

    let DatosArray = [];
    try {
      const response = await fetch(Peticion);
      const data = await response.json();
      const Datos = data.data;

      Datos.map((item) =>
        DatosArray.push(
          addFacturaPendiente(
            item.IdFactura,
            item.Fila,
            item.Fila,
            item.NomSociedad,
            item.CardName,
            item.FolioFactura,
            item.RFC,
            item.UUID,
            item.Entrada,
            item.FechaFactura,
            FormatoMoneda(item.Total),
            item.FormaPago,
            item.TentativaPago,
            item.UsoPrincipal,
            item.MetodoPago,
            item.PDF ? item.PDF : "",
            item.XML ? item.XML : "",
            item.Recepcion ? item.Recepcion : "",
            item.EstatusFactura ? item.EstatusFactura : "P",
            item.EstatusFactura ? item.EstatusFactura : "P",
            false,
            item.Comentarios ? item.Comentarios : "",
            item.BD,
            item.E_mail,
            item.DocCur ? item.DocCur : ""
          )
        )
      );

      setFacturasPendientes(DatosArray);
    } catch (error) {
      console.error(error);
    }
    setCargar(false);
  };

  React.useEffect(() => {
    fetchData(setFacturasPendientes);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setFacturasPendientes]);

  if (!UpdateAccessToken()) {
    return <Navigate to="/" />;
  }
  const handleForma = (event) => {
    event.preventDefault();
    fetchData(setFacturasPendientes);
  };

  const handleReload = () => {
    fetchData(setFacturasPendientes);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenMessage(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          display: "flex",
        }}
      >
        <Header
          IsSession={IsSession}
          open={props.open}
          setOpen={props.setOpen}
          operador={operador}
          usuario={usuario}
        />
        <MenuLateral
          IsSession={IsSession}
          open={props.open}
          setOpen={props.setOpen}
        />
        <Box
          sx={{
            flexGrow: 10,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />

          <Typography
            color="text.secondary"
            align="center"
            variant="h4"
            component="h4"
            margin={2}
          >
            Facturas pendientes
          </Typography>
          <Paper sx={{ maxWidth: "90%", margin: "auto", overflow: "hidden" }}>
            <AppBar
              position="static"
              sx={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }}
            >
              <Toolbar>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs>
                    <StyledSearch>
                      <StyledSearchIconWrapper>
                        <SearchIcon />
                      </StyledSearchIconWrapper>
                      <StyledInputBase
                        placeholder="Search…"
                        inputProps={{ "aria-label": "search" }}
                        value={filtro}
                        onChange={(e) => setFiltro(e.target.value)}
                      />
                    </StyledSearch>
                  </Grid>
                  <Grid>
                    <Tooltip title="Recargar">
                      <StyledButtonReload
                        color="inherit"
                        onClick={handleForma}
                        size="medium"
                      >
                        <RefreshIcon
                          id="btnRefreshEmpleado"
                          color="inherit"
                          sx={{ display: "block" }}
                        />
                      </StyledButtonReload>
                    </Tooltip>
                  </Grid>
                </Grid>
              </Toolbar>
              {
                <FacturasPendientesTabla
                  filtro={filtro}
                  rows={facturasPendientes}
                  setRows={setFacturasPendientes}
                  selected={selected}
                  setSelected={setSelected}
                />
              }
            </AppBar>
            <FacturasPendientesDialog
              selected={selected}
              rows={facturasPendientes}
              handleReload={handleReload}
              setError={setError}
              setErrorMensaje={setErrorMensaje}
              setOpenMessage={setOpenMessage}
              setTipoMensaje={setTipoMensaje}
            />
            {error === true && (
              <Snackbar
                anchorOrigin={{
                  vertical: "center",
                  horizontal: "center",
                }}
                open={openMessage}
                autoHideDuration={2000}
                onClose={handleClose}
              >
                <Alert
                  onClose={handleClose}
                  severity={tipoMensaje}
                  variant="filled"
                  sx={{ width: "100%" }}
                >
                  {errorMensaje}
                </Alert>
              </Snackbar>
            )}
            <StylePaperButtonReload>
              {cargar === true && <CircularProgress />}
            </StylePaperButtonReload>
          </Paper>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

FacturasPendientes.propTypes = {
  facturasPendientes: PropTypes.array,
};

export default FacturasPendientes;
