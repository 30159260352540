export function addFacturaPendiente(
  Factura,
  id,
  Numero,
  Sociedad,
  NomProveedor,
  Codigo,
  RFC,
  UUID,
  Entrada,
  Fecha,
  Total,
  FormaPago,
  TentativaPago,
  UsoPrincipal,
  MetodoPago,
  PDF,
  XML,
  Recepcion,
  Estatus,
  EstatusBD,
  Revisado,
  Comentarios,
  BD,
  Correo,
  Moneda
) {
  return {
    Factura,
    id,
    Numero,
    Sociedad,
    NomProveedor,
    Codigo,
    RFC,
    UUID,
    Entrada,
    Fecha,
    Total,
    FormaPago,
    TentativaPago,
    UsoPrincipal,
    MetodoPago,
    PDF,
    XML,
    Recepcion,
    Estatus,
    EstatusBD,
    Revisado,
    Comentarios,
    BD,
    Correo,
    Moneda,
  };
}

export const CeldasFacturasPendientes = [
  {
    id: "#",
    numeric: false,
    disablePadding: false,
    label: "#",
    minWidth: "2em",
  },
  {
    id: "Sociedad",
    numeric: false,
    disablePadding: false,
    label: "Sociedad",
    minWidth: "15em",
  },
  {
    id: "NomProveedor",
    numeric: false,
    disablePadding: false,
    label: "Nombre del proveedor",
    minWidth: "15em",
  },
  {
    id: "Codigo",
    numeric: false,
    disablePadding: false,
    label: "Código",
    minWidth: "10em",
  },
  {
    id: "RFC",
    numeric: false,
    disablePadding: false,
    label: "RFC",
    minWidth: "10em",
  },
  {
    id: "UUID",
    numeric: false,
    disablePadding: false,
    label: "UUID folio fiscal",
    minWidth: "21em",
  },
  {
    id: "Entrada",
    numeric: false,
    disablePadding: false,
    label: "Entrada",
    minWidth: "10em",
  },
  {
    id: "Fecha",
    numeric: false,
    disablePadding: false,
    label: "Fecha timbrado",
    minWidth: "12em",
  },
  {
    id: "Total",
    numeric: false,
    disablePadding: false,
    label: "Total",
    minWidth: "10em",
  },
  {
    id: "FormaPago",
    numeric: false,
    disablePadding: false,
    label: "Forma de pago",
    minWidth: "12em",
  },
  {
    id: "UsoPrincipal",
    numeric: false,
    disablePadding: false,
    label: "Uso principal",
    minWidth: "13em",
  },

  {
    id: "MetodoPago",
    numeric: false,
    disablePadding: false,
    label: "Método de pago",
    minWidth: "13em",
  },
  {
    id: "Tentativa",
    numeric: false,
    disablePadding: false,
    label: "Tentativa de pago",
    minWidth: "13em",
  },
  {
    id: "PDF",
    numeric: false,
    disablePadding: false,
    label: "PDF",
    minWidth: "100%",
  },

  {
    id: "XML",
    numeric: false,
    disablePadding: false,
    label: "XML",
    minWidth: "100%",
  },
  {
    id: "Recepcion",
    numeric: false,
    disablePadding: false,
    label: "Recepción",
  },
  {
    id: "Estatus",
    numeric: false,
    disablePadding: false,
    label: "Estatus",
    minWidth: "10em",
  },
  {
    id: "Revisado",
    numeric: false,
    disablePadding: false,
    label: "Revisado",
    minWidth: "100%",
  },
  {
    id: "Comentarios",
    numeric: false,
    disablePadding: false,
    label: "Comentarios",
    minWidth: "21em",
  },
];
