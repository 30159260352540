export function addPagoPendiente(
  Pago,
  id,
  Numero,
  Sociedad,
  NomProveedor,
  UUID,
  PagoSAP,
  Fecha,
  FechaPago,
  MetodoPago,
  TotalPago,
  FechaComplemento,
  EstatusComplemento,
  Complemento,
  PDF,
  XML,
  Revisado,
  Comentarios,
  BD,
  Correo,
  Moneda
) {
  return {
    Pago,
    id,
    Numero,
    Sociedad,
    NomProveedor,
    UUID,
    PagoSAP,
    Fecha,
    FechaPago,
    MetodoPago,
    TotalPago,
    FechaComplemento,
    EstatusComplemento,
    Complemento,
    PDF,
    XML,
    Revisado,
    Comentarios,
    BD,
    Correo,
    Moneda,
  };
}

export const CeldasPagosPendientes = [
  {
    id: "#",
    numeric: false,
    disablePadding: true,
    label: "#",
    minWidth: "2em",
  },
  {
    id: "Sociedad",
    numeric: false,
    disablePadding: true,
    label: "Sociedad",
    minWidth: "15em",
  },
  {
    id: "NomProveedor",
    numeric: false,
    disablePadding: true,
    label: "Nombre del proveedor",
    minWidth: "15em",
  },

  {
    id: "UUID",
    numeric: false,
    disablePadding: true,
    label: "UUID folio fiscal",
    minWidth: "21em",
  },
  {
    id: "Pago",
    numeric: false,
    disablePadding: true,
    label: "Pago",
    minWidth: "5em",
  },
  {
    id: "Fecha",
    numeric: false,
    disablePadding: true,
    label: "Fecha timbrado",
    minWidth: "10em",
  },
  {
    id: "FechaPago",
    numeric: false,
    disablePadding: true,
    label: "Fecha de pago",
    minWidth: "10em",
  },
  {
    id: "MetodoPago",
    numeric: false,
    disablePadding: true,
    label: "Forma de pago",
    minWidth: "8em",
  },
  {
    id: "TotalPago",
    numeric: true,
    disablePadding: false,
    label: "Total de pago",
    minWidth: "10em",
  },
  {
    id: "FechaComplemento",
    numeric: false,
    disablePadding: true,
    label: "Fecha de complemento",
    minWidth: "12em",
  },
  {
    id: "Complemento",
    numeric: false,
    disablePadding: true,
    label: "Complemento",
    minWidth: "8em",
  },
  {
    id: "PDF",
    numeric: false,
    disablePadding: true,
    label: "PDF",
    minWidth: "8em",
  },

  {
    id: "XML",
    numeric: false,
    disablePadding: true,
    label: "XML",
    minWidth: "8em",
  },
  {
    id: "EstatusComplemento",
    numeric: false,
    disablePadding: true,
    label: "Estatus de complemento",
    minWidth: "12em",
  },
  {
    id: "Revisado",
    numeric: false,
    disablePadding: true,
    label: "Revisado",
    minWidth: "10em",
  },
  {
    id: "Comentarios",
    numeric: false,
    disablePadding: true,
    label: "Comentarios",
    minWidth: "20em",
  },
];
