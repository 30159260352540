import { createTheme } from "@mui/material/styles";
import { styled, alpha } from "@mui/material/styles";
import { Button, InputBase, Paper } from "@mui/material";
import { yellow, blue } from "@mui/material/colors";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
export const theme = createTheme();

export const StyledButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(yellow[500]),
  backgroundColor: yellow[800],
  "&:hover": {
    backgroundColor: blue[800],
  },
}));

export const StyledSearch = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.35),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.15),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "10em",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "27em",
  },
}));
export const StyledSearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

export const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "10em",
    [theme.breakpoints.up("md")]: {
      width: "40ch",
    },
  },
}));

export const StyledButtonReload = styled(Button)(({ theme }) => ({
  marginLeft: theme.spacing(2),
}));

export const StyledButtonEditar = styled(Button)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  marginRight: theme.spacing(2),
}));

export const StylePaperPrincipal = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  borderColor: "#0a75f0",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  width: "35em",
  color: theme.palette.text.primary,
  margin: theme.spacing(0, 1, 1),
  borderWidth: ".2em",
}));

export const StylePaperDocumentos = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  borderColor: "#0a75f0",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  width: "55em",
  color: theme.palette.text.primary,
  margin: theme.spacing(0, 1, 1),
  borderWidth: ".2em",
}));

export const StyledDiv = styled("div")(({ theme }) => ({
  ...(window.innerWidth > 500 && {
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(1),
  }),
  alignItems: "right",
  textAlign: "right",
}));

export const SytledLabel = styled("label")(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  marginRight: theme.spacing(2),
  textAlign: "center",
  width: "10em",
}));

export const StyledTheme = styled((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export const StylePaperButton = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  width: "35em",
  height: "90%",
  color: theme.palette.text.primary,
}));

export const StyledInputFile = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export const StylePaperLoad = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(0),
  textAlign: "center",
  width: "100%",
  height: "90%",
  color: theme.palette.text.primary,
}));
