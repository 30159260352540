import React from "react";
import PropTypes from "prop-types";
import { useNavigate, Navigate } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import {
  Box,
  Grid,
  Toolbar,
  Paper,
  Typography,
  AppBar,
  Tooltip,
  CircularProgress,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import RefreshIcon from "@mui/icons-material/Refresh";
import {
  theme,
  StyledSearch,
  StyledSearchIconWrapper,
  StyledInputBase,
  StyledButton,
  StyledButtonReload,
  StylePaperLoad,
} from "./CtaEmpleados-css";
import { Header, MenuLateral } from "../Header/Header";
import { addEmpleado } from "../Data/CtaEmpleados-Data";
import CtaEmpleadosTabla from "./CtaEmpleadosTabla";
import { UpdateAccessToken } from "../Router/Cookies";
import Cookies from "js-cookie";

function CtaEmpleados(props) {
  const navigate = useNavigate();
  const [filtro, setFiltro] = React.useState("");
  const [habilitar, setHabilitar] = React.useState(false);
  const [cargar, setCargar] = React.useState(true);
  const { empleados, setEmpleados } = props;

  const usuario = Cookies.get("usuario");
  const operador = Cookies.get("operador");
  const access = Cookies.get("access");
  const tipoEmpleado = Cookies.get("tipoEmpleado");
  const id = Cookies.get("id");
  let IsSession = !!Cookies.get("access");

  React.useEffect(() => {
    fetchData(setEmpleados);
    if (operador === "Empleado" && tipoEmpleado === "Administrador") {
      setHabilitar(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [operador, setEmpleados, tipoEmpleado]);

  const fetchData = async (setEmpleados) => {
    setEmpleados([]);
    setCargar(true);
    const Values = {
      usuario: id,
      operador: operador,
      tipo: "Lista",
    };
    const Peticion = new Request("api/empleados/rowEmpleados", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-access-token": access,
      },
      method: "POST",
      body: JSON.stringify(Values),
    });

    let DatosArray = [];
    try {
      const response = await fetch(Peticion);
      const data = await response.json();
      const Datos = data.data;

      Datos.map((item) =>
        DatosArray.push(
          addEmpleado(
            item.Fila,
            item.IdEmpleado,
            item.Perfil,
            item.Nombre,
            item.Apellidos,
            item.Email,
            item.IdArea,
            item.Area,
            item.Estatus === "A" ? "Activo" : "Inactivo",
            item.Pass,
            id
          )
        )
      );

      setEmpleados(DatosArray);
    } catch (error) {
      console.error(error);
    }
    setCargar(false);
  };

  if (!UpdateAccessToken()) {
    return <Navigate to="/" />;
  }

  const OnButtonClick = (event) => {
    if (event.target.id === "btnCrear") {
      navigate("/Catalogo-Empleados/Empleado/Nuevo");
    }
  };

  const handleForma = (event) => {
    event.preventDefault();
    fetchData(setEmpleados);
  };

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          display: "flex",
        }}
      >
        <Header
          IsSession={IsSession}
          open={props.open}
          setOpen={props.setOpen}
          operador={operador}
          usuario={usuario}
        />
        <MenuLateral
          IsSession={IsSession}
          open={props.open}
          setOpen={props.setOpen}
        />
        <Box
          sx={{
            flexGrow: 10,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />
          <Typography
            color="text.secondary"
            align="center"
            variant="h4"
            component="h4"
            margin={2}
          >
            Catálogo de empleados
          </Typography>
          <Paper sx={{ maxWidth: "90%", margin: "auto", overflow: "hidden" }}>
            <AppBar
              position="static"
              sx={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }}
            >
              <Toolbar>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs>
                    <StyledSearch>
                      <StyledSearchIconWrapper>
                        <SearchIcon />
                      </StyledSearchIconWrapper>
                      <StyledInputBase
                        placeholder="Search…"
                        inputProps={{ "aria-label": "search" }}
                        value={filtro}
                        onChange={(e) => setFiltro(e.target.value)}
                      />
                    </StyledSearch>
                  </Grid>
                  <Grid item xs align="right">
                    {habilitar === true && (
                      <StyledButton id="btnCrear" onClick={OnButtonClick}>
                        Agregar
                      </StyledButton>
                    )}
                    <Tooltip title="Recargar">
                      <StyledButtonReload
                        color="inherit"
                        onClick={handleForma}
                        size="medium"
                      >
                        <RefreshIcon
                          id="btnRefreshEmpleado"
                          color="inherit"
                          sx={{ display: "block" }}
                        />
                      </StyledButtonReload>
                    </Tooltip>
                  </Grid>
                </Grid>
              </Toolbar>
              <CtaEmpleadosTabla filtro={filtro} rows={empleados} />
              <StylePaperLoad>
                {cargar === true && <CircularProgress />}
              </StylePaperLoad>
            </AppBar>
          </Paper>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

CtaEmpleados.propTypes = {
  usuario: PropTypes.string,
  empleados: PropTypes.array,
};

export default CtaEmpleados;
