import React from 'react';
import { Routes, Route } from "react-router-dom";
import { AuthRoute, PrivateRoute } from './Components/Router/Cookies'
import Principal from './Components/Principal/Principal';
import Login from './Components/Login/Login';
import Aviso from './Components/AvisoPrivacidad/AvisoPrivacidad'
import RecuperarPassword from './Components/RecuperarPassword/RecuperarPassword';
import Home from './Components/Home/Home';
import Perfil from './Components/Perfil/Perfil';
import CtaEmpleados from './Components/CtaEmpleados/CtaEmpleados';
import Empleado from './Components/CtaEmpleados/Empleado';
import CtaProveedores from './Components/CtaProveedores/CtaProveedores';
import Proveedor from './Components/CtaProveedores/Proveedor';
import TablaAgregarSociedad from './Components/CtaProveedores/Sociedades/TablaAgregarSociedad'
import CtaSociedades from './Components/CtaSociedades/CtaSociedades';
import Sociedad from './Components/CtaSociedades/Sociedad';
import Pedidos from './Components/Pedidos/Pedidos';
import Pedido from './Components/Pedidos/Pedido';
import EntradasMercancia from './Components/Entradas/EntradaMercancias';
import EntradaMercancia from './Components/Entradas/EntradaMercancia';
import MisFacturas from './Components/MisFacturas/MisFacturas';
import Factura from './Components/MisFacturas/Factura.jsx';
import DetalleFactura from './Components/MisFacturas/DetalleFactura.jsx';
import FacturasPendientes from './Components/FacturasPendientes/FacturasPendientes';
import Pagos from './Components/Pagos/Pagos';
import Pago from './Components/Pagos/Pago.jsx'
import PagosPendientes from './Components/PagosPendientes/PagosPendientes.jsx';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';
function App() {

  const [open, setOpen] = React.useState(false);
  const [datos, setDatos] = React.useState([]);
  const [dperfil, setDPerfil] = React.useState([]);
  const [perfil, setPerfil] = React.useState(false);

  React.useEffect(() => {
    serviceWorkerRegistration.register({
      onUpdate: (registration) => {
        if (registration && registration.waiting) {
          // // Notifica al usuario que hay una actualización disponible
          // if (window.confirm("Hay una nueva versión disponible. ¿Deseas actualizar?")) {
          //   registration.waiting.postMessage({ type: 'SKIP_WAITING' });
          //   window.location.reload(); // Recarga la página
          // }
        }
      },
    });
  }, []);

  return (
    <>
      <Routes>
        <Route
          path="/"
          element={<Principal setOpen={setOpen} open={open} />
          }
        />
        <Route
          path="/Login"
          element={<Login setOpen={setOpen} open={open} />
          }
        />

        <Route
          path="/AvisoDePrivacidad"
          element={<Aviso setOpen={setOpen} open={open} />
          }
        />
        <Route
          path="/RecuperarPassword"
          element={<RecuperarPassword setOpen={setOpen} open={open} />
          }
        />

        <Route
          path="/Home"
          element={<PrivateRoute> <Home setOpen={setOpen} open={open} datos={dperfil} setDatos={setDPerfil} /> </PrivateRoute>}
        />
        <Route
          path="/Perfil"
          element={<PrivateRoute><Perfil setOpen={setOpen} open={open} tipoPerfil={perfil} setTipoPerfil={setPerfil} datos={dperfil} setDatos={setDPerfil} /></PrivateRoute>
          }
        />
        <Route
          path="/Catalogo-Empleados"
          element={<AuthRoute><CtaEmpleados setOpen={setOpen} open={open} empleados={datos} setEmpleados={setDatos} /> </AuthRoute>}
        />
        <Route
          path="/Catalogo-Empleados/Empleado/:id"
          element={<AuthRoute><Empleado setOpen={setOpen} open={open} empleados={datos} setEmpleados={setDatos} /> </AuthRoute>}
        />
        <Route
          path="/Catalogo-Proveedores"
          element={<AuthRoute><CtaProveedores setOpen={setOpen} open={open} proveedores={datos} setProveedores={setDatos} /> </AuthRoute>}
        />
        <Route
          path="/Catalogo-Proveedores/Proveedor/:id"
          element={<AuthRoute><Proveedor setOpen={setOpen} open={open} proveedores={datos} setProveedores={setDatos} perfil={perfil} setPerfil={setPerfil} /> </AuthRoute>}
        />
        <Route
          path="/Catalogo-Proveedores/Proveedor/:id/Sociedad/:userId"
          element={<AuthRoute><TablaAgregarSociedad setOpen={setOpen} open={open} proveedores={datos} setProveedores={setDatos} /> </AuthRoute>}
        />

        <Route
          path="/Catalogo-Sociedades"
          element={<AuthRoute><CtaSociedades setOpen={setOpen} open={open} sociedades={datos} setSociedades={setDatos} /> </AuthRoute>}
        />
        <Route
          path="/Catalogo-Sociedades/Sociedad/:id"
          element={<AuthRoute><Sociedad setOpen={setOpen} open={open} sociedades={datos} setSociedades={setDatos} /> </AuthRoute>}
        />
        <Route
          path="/Pedidos"
          element={<AuthRoute><Pedidos setOpen={setOpen} open={open} pedidos={datos} setPedidos={setDatos} /> </AuthRoute>}
        />
        <Route
          path="/Pedidos/Pedido/:id"
          element={<AuthRoute><Pedido setOpen={setOpen} open={open} pedidos={datos} setPedidos={setDatos} /> </AuthRoute>}
        />
        <Route
          path="/Entradas-Mercancia"
          element={<AuthRoute><EntradasMercancia setOpen={setOpen} open={open} entradaMercancias={datos} setEntradaMercancias={setDatos} /> </AuthRoute>}
        />
        <Route
          path="/Entradas-Mercancia/EntradaMercancia/:id"
          element={<AuthRoute><EntradaMercancia setOpen={setOpen} open={open} entradaMercancias={datos} setEntradaMercancias={setDatos} /> </AuthRoute>}
        />
        <Route
          path="/MisFacturas"
          element={<AuthRoute><MisFacturas setOpen={setOpen} open={open} misFacturas={datos} setMisFacturas={setDatos} /> </AuthRoute>}
        />
        <Route
          path="/MisFacturas/Factura/:id"
          element={<AuthRoute><Factura setOpen={setOpen} open={open} misFacturas={datos} setMisFacturas={setDatos} /> </AuthRoute>}
        />
        <Route
          path="/MisFacturas/MiFactura/:id"
          element={<AuthRoute><DetalleFactura setOpen={setOpen} open={open} misFacturas={datos} setMisFacturas={setDatos} /> </AuthRoute>}
        />
        <Route
          path="/Facturas-Pendientes"
          element={<AuthRoute><FacturasPendientes setOpen={setOpen} open={open} facturasPendientes={datos} setFacturasPendientes={setDatos} /> </AuthRoute>}
        />
        <Route
          path="/Pagos"
          element={<AuthRoute><Pagos setOpen={setOpen} open={open} pagos={datos} setPagos={setDatos} /> </AuthRoute>}
        />
        <Route
          path="/Pagos/Pago/:id"
          element={<AuthRoute><Pago setOpen={setOpen} open={open} pagos={datos} setPagos={setDatos} /> </AuthRoute>}
        />
        <Route
          path="/Pagos-Pendientes"
          element={<AuthRoute><PagosPendientes setOpen={setOpen} open={open} pagosPendientes={datos} setPagosPendientes={setDatos} /> </AuthRoute>}
        />
        <Route path="*" element={<div>No page</div>} />
      </Routes>
    </>
  );
}


export default App;
