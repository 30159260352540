import { createTheme } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { Button } from "@mui/material";
import { yellow, blue } from "@mui/material/colors";
import Card from "@mui/material/Card";

export const theme = createTheme();

export const StyledPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  textAlign: "center",
  color: theme.palette.text.secondary,
  height: 165,
  width: 120,
  margin: theme.spacing(0, 1, 0),
}));

export const StyledCard = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  textAlign: "center",
  color: theme.palette.text.secondary,
  height: 165,
  width: 120,
  margin: theme.spacing(0, 1, 0),
}));

export const Styleddiv = styled("div")(({ theme }) => ({
  backgroundColor: "#1A2027",
  marginTop: theme.spacing(1),
}));

export const StyledButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(yellow[500]),
  backgroundColor: yellow[800],
  "&:hover": {
    backgroundColor: blue[800],
  },
}));
